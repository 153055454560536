import React, { useState, useEffect, useRef } from "react";
import Header from "../components/Header";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import {
  MdAdd,
  MdCreate,
  MdRemove,
  MdOutlineDelete,
  MdModeEdit,
  MdFileUpload,
  MdUploadFile,
  MdUpload,
} from "react-icons/md";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import SendIcon from "@mui/icons-material/Send";
import Modal from "@mui/material/Modal";
import Slide from "@mui/material/Slide";

import validator from "ecuador-validator";
import Papa from "papaparse";

import { DataGrid } from "@mui/x-data-grid";
// Data
import {
  PreGetLotteries,
  GetLotteries,
  SaveLottery,
  RemoveLottery,
  UpdateLottery,
  SaveManyLottery,
} from "../api/lotteries";
import UploadFile from "../UploadFile";

// Contexts
import { useStateContext } from "../contexts/ContextProvider";

// UPLOAD
import axios from "axios";
import { api, configForUpload } from "../api/index";

const MAX_IMAGE_SIZE = process.env.REACT_APP_MAX_IMAGE_SIZE;
const MAX_VIDEO_SIZE = process.env.REACT_APP_MAX_VIDEO_SIZE;
const MAX_IMAGE_WIDTH = process.env.REACT_APP_MAX_IMAGE_WIDTH;
const MAX_IMAGE_HEIGHT = process.env.REACT_APP_MAX_IMAGE_HEIGHT;

const API_UPLOAD_ENDPOINT = "/upload";
const API_ENDPOINT = "/lottery";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

// Transitions
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// // load CSV
// const allowedExtensions=["csv"];

PreGetLotteries();

//************************************************************************************************************ */
export function Lotteries(props) {
  const { data, error, isLoading, isValidating } = GetLotteries();

  const [seed, setSeed] = useState(1);
  const resetTable = () => {
    setSeed(Math.random());
  };

  // Global Context
  const {
    setOpenGlobalModal,
    setMessageGlobalModal,
    setOpenGlobalModalError,
    setMessageGlobalModalError,
  } = useStateContext();

  const { onClose, value: valueProp, open, ...other } = props;

  const [openDialog, setOpenDialog] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const [messageModal, setMessageModal] = useState("");

  const [inputName, setInputName] = useState("");
  const [errorName, setErrorName] = useState(false);

  const [inputControlCode, setInputControlCode] = useState("");
  const [errorControlCode, setErrorControlCode] = useState(false);

  const [inputTicketCost, setInputTicketCost] = useState("");
  const [errorTicketCost, setErrorTicketCost] = useState(false);

  const [inputImageUrl, setInputImageUrl] = useState("");
  const [errorImageUrl, setErrorImageUrl] = useState(false);
  const [errorImageUrlMessage, setErrorImageUrlMessage] = useState(
    "Ingrese una imagen valida."
  );

  const [inputVideoUrl, setInputVideoUrl] = useState("");
  const [errorVideoUrl, setErrorVideoUrl] = useState(false);
  const [errorVideoUrlMessage, setErrorVideoUrlMessage] = useState(
    "Ingrese un video valido."
  );

  const [inputDueDate, setInputDueDate] = useState("");
  const [errorDueDate, setErrorDueDate] = useState(false);

  const [showRemove, setShowRemove] = useState(false);
  const [showEdit, setShowEdit] = useState(false);

  const [openConfirmation, setOpenConfirmation] = useState(false);

  const [lotterySelected, setLotterySelected] = useState(null);

  // State to store parsed data
  const [parsedData, setParsedData] = useState([]);

  //State to store table Column name
  const [tableRows, setTableRows] = useState([]);

  //State to store the values
  const [values, setValues] = useState([]);

  // For UPLOADS
  const [selectedImageFile, setSelectedImageFile] = useState(null);
  const [selectedVideoFile, setSelectedVideoFile] = useState(null);

  const inputImageRef = useRef();
  const inputVideoRef = useRef();

  const openFileImageInput = () => {
    inputImageRef.current.value = "";
    inputImageRef.current.click(); // simulate click on file selected
  };

  const openFileVideoInput = () => {
    inputVideoRef.current.value = "";
    inputVideoRef.current.click(); // simulate click on file selected
  };

  const handleOnChangeImageFile = (event) => {
    let imageFile = event.target.files[0];
    // console.log(imageFile);

    if (
      !imageFile.name ||
      imageFile.name === "" ||
      imageFile.type !== "image/png"
    ) {
      setErrorImageUrlMessage(
        "El archivo no es una imagen valida debe ser tipo jpg o png"
      );
      setErrorImageUrl(true);
      setInputImageUrl("");
      return;
    }

    const img = new Image();
    // Set the src attribute to the selected image
    img.src = URL.createObjectURL(imageFile);
    // Wait for the image to load
    img.onload = function () {
      // Access the width and height
      const imageWidth = img.width;
      const imageHeight = img.height;

      // Now you can use imageWidth and imageHeight as needed
      // console.log(`Image dimensions: ${imageWidth} x ${imageHeight} MAX_IMAGE_WIDTH: ${MAX_IMAGE_WIDTH}, MAX_IMAGE_SIZE: ${MAX_IMAGE_SIZE}`);

      if (imageWidth > MAX_IMAGE_WIDTH || imageHeight > MAX_IMAGE_HEIGHT) {
        setErrorImageUrlMessage(
          `La imagen debe medir maximo ${MAX_IMAGE_WIDTH}x${MAX_IMAGE_HEIGHT} pixels`
        );
        setErrorImageUrl(true);
        setInputImageUrl("");
        return;
      }

      if (imageFile.size > MAX_IMAGE_SIZE) {
        setErrorImageUrlMessage(
          `La imagen debe pesar maximo ${
            MAX_IMAGE_SIZE / 1024 / 1024
          } Mega Bytes`
        );
        setErrorImageUrl(true);
        setInputImageUrl("");
        return;
      }
    };

    setErrorImageUrlMessage("Seleccione una imagen valida.");
    setErrorImageUrl(false);
    setSelectedImageFile(imageFile);
    setInputImageUrl(imageFile.name);
  };

  const handleOnChangeVideoFile = (event) => {
    let videoFile = event.target.files[0];
    // console.log(videoFile);

    if (
      !videoFile.name ||
      videoFile.name === "" ||
      videoFile.type !== "video/mp4"
    ) {
      setErrorVideoUrl(true);
      return;
    }
    // console.log(`${videoFile.size}>${MAX_VIDEO_SIZE}`);
    if (videoFile.size > MAX_VIDEO_SIZE) {
      setErrorVideoUrlMessage(
        `El video debe pesar maximo ${MAX_VIDEO_SIZE / 1024 / 1024} Mega Bytes`
      );
      setErrorVideoUrl(true);
      setInputVideoUrl("");
      return;
    }

    setErrorVideoUrlMessage("Debe ingresar un video valido.");
    setErrorVideoUrl(false);
    setSelectedVideoFile(videoFile);
    setInputVideoUrl(videoFile.name);
  };

  const handleUploadImage = () => {
    // console.log('Uploading image');
    const formData = new FormData();
    formData.append("file", selectedImageFile);
    return api
      .post(`${API_UPLOAD_ENDPOINT}`, formData, configForUpload)
      .then((response) => {
        // console.log(response.data);
        if (response.status == 200 && response.statusText === "OK") {
          return true;
        } else {
          return false;
        }
      })
      .catch((error) => {
        // console.log(error);
        return false;
      });
  };

  const handleUploadVideo = () => {
    // console.log('Uploading video');
    const formData = new FormData();
    formData.append("file", selectedVideoFile);
    return api
      .post(`${API_UPLOAD_ENDPOINT}`, formData, configForUpload)
      .then((response) => {
        // console.log(response.data);
        if (response.status == 200 && response.statusText === "OK") {
          return true;
        } else {
          return false;
        }
      })
      .catch((error) => {
        // console.log(error);
        return false;
      });
  };

  // Context states
  const { currentColor, currentMode } = useStateContext();

  // Custom Button
  const NavButton = ({
    title,
    customFunc,
    icon,
    color,
    dotColor,
    visible = true,
  }) => {
    if (!visible) return <></>;
    return (
      <Tooltip content={title} placement="bottom-start">
        <button
          type="button"
          onClick={customFunc}
          style={{ color }}
          className="relative text-4xl rounded-full p-3 hover:bg-light-gray"
        >
          <span
            style={{ background: dotColor }}
            className="absolute inline-flex rounded-full h-2 w-2 right-2 top-2"
          />
          {icon}
        </button>
      </Tooltip>
    );
  };

  // Colums
  const columns = [
    { field: "lottery_id", headerName: "ID", width: 70 },
    { field: "lottery_name", headerName: "Nombre", width: 250 },
    { field: "lottery_control_code", headerName: "Cod. Control", width: 100 },
    { field: "lottery_ticket_cost", headerName: "Costo", width: 100 },
    { field: "lottery_image_url", headerName: "Imagen", width: 300 },
    { field: "lottery_video_url", headerName: "Video", width: 300 },
    { field: "lottery_due_date", headerName: "Fecha Sorteo", width: 150 },
    {
      field: "click_edit",
      headerName: "Modificar",
      width: 90,
      renderCell: (params) => {
        return (
          <div className="flex justify-center items-center w-full text-2xl">
            <button onClick={() => handleClickEdit(params)}>
              <MdModeEdit />
            </button>
          </div>
        );
      },
    },
    {
      field: "click_remove",
      headerName: "Eliminar",
      width: 90,
      renderCell: (params) => {
        return (
          <div className="flex justify-center items-center w-full text-2xl">
            <button onClick={() => handleClickRemove(params)}>
              <MdOutlineDelete />
            </button>
          </div>
        );
      },
    },
    // {
    //   field: "age",
    //   headerName: "Age",
    //   type: "number",
    //   width: 90,
    // },
    // {
    //   field: "fullName",
    //   headerName: "Full name",
    //   description: "This column has a value getter and is not sortable.",
    //   sortable: false,
    //   width: 160,
    //   valueGetter: (params) =>
    //     `${params.row.firstName || ""} ${params.row.lastName || ""}`,
    // },
  ];
  // *************** handlers

  const handleClickRemove = (params) => {
    setLotterySelected({
      lottery_id: params.row.lottery_id,
      lottery_name: params.row.lottery_name,
      lottery_control_code: params.row.lottery_control_code,
      lottery_ticket_cost: params.row.lottery_ticket_cost,
      lottery_image_url: params.row.lottery_image_url,
      lottery_video_url: params.row.lottery_video_url,
      lottery_due_date: params.row.lottery_due_date,
    });

    setOpenConfirmation(true);
  };

  const handleRemoving = () => {
    RemoveLottery(lotterySelected.lottery_id, lotterySelected)
      .then((response) => {
        setMessageGlobalModal("El sorteo se elimino correctamente.");
        setOpenGlobalModal(true);
      })
      .catch((error) => {
        setMessageModal(
          `Error al intentar eliminar sorteo [${error.message}].`
        );
        setOpenGlobalModal(true);
      });

    handleCloseConfirmation();
  };

  const handleClickEdit = (params) => {
    setLotterySelected({
      lottery_id: params.row.lottery_id,
      lottery_name: params.row.lottery_name,
      lottery_control_code: params.row.lottery_control_code,
      lottery_ticket_cost: params.row.lottery_ticket_cost,
      lottery_image_url: params.row.lottery_image_url,
      lottery_video_url: params.row.lottery_video_url,
      lottery_due_date: params.row.lottery_due_date,
    });
    // Load form dilog with custom data
    setInputName(params.row.lottery_name);
    setInputControlCode(params.row.lottery_control_code);
    setInputTicketCost(params.row.lottery_ticket_cost);
    setInputImageUrl(params.row.lottery_image_url);
    setInputVideoUrl(params.row.lottery_video_url);
    setInputDueDate(params.row.lottery_due_date.split("T")[0]);

    setOpenDialog(true);
  };

  const handleClickUpload = () => {
    window.alert("upload file");
  };

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setLotterySelected(null);
    cleanFields();
    setOpenDialog(false);
  };

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handleInputName = (event) => {
    const value = event.target.value;
    if (value.length < 1 || value.length > 150) {
      setInputName(value.substring(0, 150));
      // setErrorName(true)
    } else {
      setErrorName(false);
      setInputName(value);
    }
  };

  const handleInputControlCode = (event) => {
    const value = event.target.value;
    if (value.length < 1 || value.length > 4) {
      setInputControlCode(value.substring(0, 4));
      // setErrorControlCode(true)
    } else {
      setErrorControlCode(false);
      setInputControlCode(value);
    }
  };

  const handleInputTicketCost = (event) => {
    const value = event.target.value;
    if (value.length < 1 || value.length > 3) {
      setInputTicketCost(value.substring(0, 3));
      // setErrorTicketCost(true)
    } else {
      setErrorTicketCost(false);
      setInputTicketCost(value);
    }
  };

  const handleInputImageUrl = (event) => {
    const value = event.target.value;
    if (value.length < 5 || value.length > 500) {
      setInputImageUrl(value.substring(0, 500));
      // setErrorImageUrl(true)
    } else {
      setErrorImageUrl(false);
      setInputImageUrl(value);
    }
  };

  const handleInputVideoUrl = (event) => {
    const value = event.target.value;
    if (value.length > 500) {
      setInputVideoUrl(value.substring(0, 500));
      // setErrorVideoUrl(true)
    } else {
      setErrorVideoUrl(false);
      setInputVideoUrl(value);
    }
  };

  const handleInputDueDate = (event) => {
    const newDate = event.target.value.split("T")[0];
    // const value = event.target.value;
    if (newDate.length < 10 || newDate.length > 10) {
      setInputDueDate(newDate.substring(0, 10));
      // setErrorDueDate(true)
    } else {
      setErrorDueDate(false);
      setInputDueDate(newDate);
    }
  };

  const handleCloseConfirmation = () => {
    setLotterySelected(null);
    setOpenConfirmation(false);
  };
  const handleOpenConfirmation = () => {
    setOpenConfirmation(true);
  };

  const cleanFields = () => {
    setInputName("");
    setInputControlCode("");
    setInputTicketCost("");
    setInputImageUrl("");
    setInputVideoUrl("");
    setInputDueDate("");
  };

  // // Data Load CSV
  // const handleFileChange = (e) => {
  //   Papa.parse(event.target.files[0], {
  //     header: true,
  //     skipEmptyLines: true,
  //     complete: function (results) {
  //       const rowsArray = [];
  //       const valuesArray = [];

  //       // Iterating data to get column name and their values
  //       results.data.map((d) => {
  //         rowsArray.push(Object.keys(d));
  //         valuesArray.push(Object.values(d));
  //       });

  //       if (!(rowsArray[0][0]==='nombre' && rowsArray[0][1]==='apellido' && rowsArray[0][2]==='cedula' && rowsArray[0][3]==='telefono')){
  //         setMessageGlobalModal("El archivo no tiene el formato correcto (nombre, apellido, cedula, telefono).");
  //         setOpenGlobalModal(true);
  //         return;
  //       }

  //       // Parsed Data Response in array format
  //       setParsedData(results.data);

  //       // Filtered Column Names
  //       setTableRows(rowsArray[0]);

  //       // Filtered Values
  //       setValues(valuesArray);

  //       let arrayPayload= [];
  //       let invalidIdentifications="";
  //       let invalidPhones="";

  //       valuesArray.map((item)=>{

  //           // Valido la identificacion y el telefono
  //           if (
  //             !validator.ci(item[2]) &&
  //             !validator.ruc(item[2])
  //           ) {
  //             invalidIdentifications += `${item[2]}, `;
  //           }

  //           if (
  //             !validator.cellphone(item[3])
  //           ) {
  //             invalidPhones += `${item[3]}, `;
  //           }

  //           const itemPayload={
  //             customer_first_name: item[0],
  //             customer_last_name: item[1],
  //             customer_ticket_cost: item[2],
  //             customer_image_url: item[3]
  //           };
  //           arrayPayload.push(itemPayload);
  //         });

  //       if (invalidIdentifications.length>0) invalidIdentifications = `Cedula/RUC invalidos: ${invalidIdentifications}`
  //       if (invalidPhones.length>0) invalidPhones = `Celulares invalidos: ${invalidPhones}`
  //       if (invalidIdentifications.length>0 || invalidPhones.length>0) {
  //         setMessageGlobalModal(
  //           `${invalidIdentifications}\r\n ${invalidPhones}`
  //         );
  //         setOpenGlobalModal(true);
  //         return;
  //       }

  //       SaveManyLottery({arrayPayload})
  //       .then((res) => {
  //         setMessageGlobalModal("El se cargo correctamente.");
  //         setOpenGlobalModal(true);

  //         //clean fields
  //         cleanFields();
  //       })
  //       .catch((error) => {
  //         setMessageGlobalModal(
  //           `Error al intentar grabar datos del archivo [${error.message}].\r\nRevise por favor.`
  //         );
  //         setOpenGlobalModal(true);
  //       });

  //     },
  //   });

  // };

  /* BACKDROP */
  const handleBackdropClick = (e) => {
    //these fail to keep the modal open
    e.stopPropagation();
    return false;
  };

  return (
    <div className="m-2 md:m-10 p-2 md:p-10 bg-white bg-opacity-80 rounded-3xl ">
      <div className="flex flex-row justify-between items-center">
        <Header category="Página" title="Sorteos" />
        <div>
          <NavButton
            title="Agregar Socio"
            customFunc={() => handleClickOpen()}
            color={currentColor}
            icon={<MdAdd />}
          />
          {/* *** LOAD FILE CSV *** */}
          {/* <input onChange={handleFileChange} ref={inputRef} type="file" id="upload" style={{display: "none"}} />
          <NavButton
            title="Cargar Socios"
            customFunc={() => openFileInput()}
            color={currentColor}
            icon={<MdUploadFile />}
          /> */}
        </div>
      </div>
      <div>
        <div>
          {/* DIALOG CONFIRMATION  */}
          <Dialog
            open={openConfirmation}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleCloseConfirmation}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle>{`Eliminar a ${lotterySelected?.lottery_name} ? `}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                Desea eliminar este sorteo de la plataforma ?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseConfirmation}>Cancelar</Button>
              <Button onClick={handleRemoving}>Aceptar</Button>
            </DialogActions>
          </Dialog>

          {/* *** FORM DIALOG *** */}
          <Dialog
            sx={{ "& .MuiDialog-paper": { width: "100%", maxHeight: 500 } }}
            maxWidth="xs"
            {...other}
            open={openDialog}
            onClick={(e) => e.stopPropagation()}
            disableEscapeKeyDown={true}
            onClose={handleClose}
            PaperProps={{
              component: "form",
              onSubmit: (event) => {
                event.preventDefault();
                const formData = new FormData(event.currentTarget);
                const formJson = Object.fromEntries(formData.entries());

                // Validation
                const _name = formJson.name;
                const _control_code = formJson.controlCode;
                const _ticket_cost = formJson.ticketCost;
                const _image_url = formJson.imageUrl;
                const _video_url = formJson.videoUrl;
                const _due_date = formJson.dueDate;

                // if there image and fail upload
                if (_image_url.length > 0) {
                  const uploadImageConfirmation = handleUploadImage();
                  if (!uploadImageConfirmation) {
                    _image_url = "Upload Failed";
                    setInputImageUrl(_image_url);
                  }
                }

                // if there video and fail upload
                if (_video_url.length > 0) {
                  const uploadVideoConfirmation = handleUploadVideo();
                  if (!uploadVideoConfirmation) {
                    _video_url = "Upload Failed";
                    setInputVideoUrl(_image_url);
                  }
                }

                // Enviar al ENDPOINT y espeerar respuesta antes de cerrar
                let payload;
                if (!lotterySelected) {
                  /*  NEW RECORD */

                  payload = {
                    lotteryName: _name,
                    lotteryControlCode: _control_code,
                    lotteryTicketCost: _ticket_cost,
                    lotteryImageUrl: _image_url,
                    lotteryVideoUrl: _video_url,
                    lotteryDueDate: _due_date,
                  };
                  SaveLottery(payload)
                    .then((res) => {
                      setMessageGlobalModal(
                        "El Sorteo se grabo correctamente."
                      );
                      setOpenGlobalModal(true);
                      handleClose();

                      //clean fields
                      cleanFields();
                      handleClose();
                    })
                    .catch((error) => {
                      setMessageModal(
                        `Error al intentar grabar sorteo [${error.message}].\r\nRevise por favor.`
                      );
                      setOpenModal(true);
                    });
                } else {
                  /*  UPDATE RECORD */

                  payload = {
                    lotteryId: lotterySelected.lottery_id,
                    lotteryName: _name,
                    lotteryControlCode: _control_code,
                    lotteryTicketCost: _ticket_cost,
                    lotteryImageUrl: _image_url,
                    lotteryVideoUrl: _video_url,
                    lotteryDueDate: _due_date,
                  };
                  UpdateLottery(payload)
                    .then((res) => {
                      setMessageGlobalModal(
                        "El Sorteo se actualizo correctamente."
                      );
                      setOpenGlobalModal(true);
                      handleClose();

                      //clean fields
                      cleanFields();
                      handleClose();
                    })
                    .catch((error) => {
                      setMessageGlobalModal(
                        `Error al intentar actualizar sorteo [${error.message}].\r\nRevise por favor.`
                      );
                      setOpenGlobalModal(true);
                    });
                }
                resetTable();
              },
            }}
          >
            <DialogTitle>
              {!lotterySelected ? "AGREGAR SORTEO" : "MODIFICAR SORTEO"}
            </DialogTitle>
            <DialogContent>
              <div>
                {/* MODAL */}
                <Modal
                  keepMounted
                  open={openModal}
                  onClose={handleCloseModal}
                  aria-labelledby="keep-mounted-modal-title"
                  aria-describedby="keep-mounted-modal-description"
                >
                  <Box sx={style}>
                    <Typography
                      id="keep-mounted-modal-title"
                      variant="h6"
                      component="h2"
                    >
                      {messageModal}
                    </Typography>
                  </Box>
                </Modal>
              </div>
              {/* <DialogContentText>
                NUEVO SOCIO
              </DialogContentText> */}
              <TextField
                autoFocus
                required
                margin="dense"
                id="name"
                name="name"
                label="Nombre"
                type="text"
                fullWidth
                variant="standard"
                value={inputName}
                onChangeCapture={handleInputName}
                error={errorName}
                helperText="Debe ingresar un nombre valido."
              />
              <TextField
                required
                margin="dense"
                id="controlCode"
                name="controlCode"
                label="Codigo Control"
                type="text"
                fullWidth
                variant="standard"
                value={inputControlCode}
                onChangeCapture={handleInputControlCode}
                error={errorControlCode}
                helperText="Debe ingresar un codigo control valido."
              />
              <TextField
                required
                margin="dense"
                id="ticketCost"
                name="ticketCost"
                label="Precio Boleto"
                type="number"
                fullWidth
                variant="standard"
                value={inputTicketCost}
                onChangeCapture={handleInputTicketCost}
                error={errorTicketCost}
                helperText="Debe ingresar una precio valido."
              />
              <div className=" flex items-start justify-between">
                <div className=" w-5/6">
                  <TextField
                    required
                    margin="dense"
                    id="imageUrl"
                    name="imageUrl"
                    label="Url Imagen"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={inputImageUrl}
                    onChangeCapture={handleInputImageUrl}
                    error={errorImageUrl}
                    helperText={errorImageUrlMessage}
                  />
                </div>
                <div>
                  <input
                    onChange={handleOnChangeImageFile}
                    ref={inputImageRef}
                    type="file"
                    id="upload"
                    style={{ display: "none" }}
                  />
                  <NavButton
                    title="Cargar Imagen"
                    customFunc={() => openFileImageInput()}
                    color={currentColor}
                    icon={<MdUploadFile />}
                  />
                </div>
              </div>
              {/* <UploadFile title="Imagen" /> */}

              <div className=" flex items-start justify-between">
                <div className=" w-5/6">
                  <TextField
                    //required
                    margin="dense"
                    id="videoUrl"
                    name="videoUrl"
                    label="Url Video"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={inputVideoUrl}
                    onChangeCapture={handleInputVideoUrl}
                    error={errorVideoUrl}
                    helperText={errorVideoUrlMessage}
                  />
                </div>
                <div>
                  <input
                    onChange={handleOnChangeVideoFile}
                    ref={inputVideoRef}
                    type="file"
                    id="upload"
                    style={{ display: "none" }}
                  />
                  <NavButton
                    title="Cargar Video"
                    customFunc={() => openFileVideoInput()}
                    color={currentColor}
                    icon={<MdUploadFile />}
                  />
                </div>
              </div>
              {/* <UploadFile title="Video" /> */}

              <TextField
                required
                margin="dense"
                id="dueDate"
                name="dueDate"
                label="Fecha Sorteo"
                type="date"
                fullWidth
                variant="standard"
                value={inputDueDate}
                onChangeCapture={handleInputDueDate}
                error={errorDueDate}
                helperText="Debe ingresar una fecha valida."
              />
            </DialogContent>
            <DialogActions>
              <Button variant="outlined" color="error" onClick={handleClose}>
                Cancelar
              </Button>
              <Button type="submit" variant="contained" endIcon={<SendIcon />}>
                Grabar
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        {data && (
          <Box sx={{ width: "100%" }}>
            <DataGrid
              key={seed}
              rows={data}
              columns={columns}
              getRowId={(row) => row?.lottery_id}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 5 },
                },
              }}
              pageSizeOptions={[5, 10]}
              // checkboxSelection
            />
          </Box>
        )}
      </div>
    </div>
  );
}

export default Lotteries;
