import React, { useState, useEffect, useRef } from "react";
import Header from "../components/Header";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { MdAdd, MdOutlineDelete, MdModeEdit, MdUploadFile, MdPhoneIphone } from "react-icons/md";
import { HiOutlineIdentification } from "react-icons/hi2";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import SendIcon from "@mui/icons-material/Send";
import Modal from "@mui/material/Modal";
import Slide from '@mui/material/Slide';

import validator from "ecuador-validator";
import Papa from 'papaparse';

import { DataGrid } from "@mui/x-data-grid";

import { CSVLink } from 'react-csv';

// Data
import { PreGetCustomers, GetCustomers, SaveCustomer, RemoveCustomer, UpdateCustomer, SaveManyCustomer } from "../api/customer";

// Contexts
import { useStateContext } from "../contexts/ContextProvider";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

// Transitions
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// load CSV
const allowedExtensions=["csv"];

PreGetCustomers();

//************************************************************************************************************ */
export function Customers(props) {
  const { data, error, isLoading, isValidating } = GetCustomers();

  const [seed, setSeed] = useState(1);
  const resetTable = () => {
       setSeed(Math.random());
  }

  // Global Context
  const {
    setOpenGlobalModal,
    setMessageGlobalModal,
    setOpenGlobalModalError,
    setMessageGlobalModalError,
  } = useStateContext();

  const { onClose, value: valueProp, open, ...other } = props;

  const [openDialog, setOpenDialog] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const [messageModal, setMessageModal] = useState("");

  const [inputFirstName, setInputFirstName] = useState("");
  const [errorFirstName, setErrorFirstName] = useState(false);

  const [inputLastName, setInputLastName] = useState("");
  const [errorLastName, setErrorLastName] = useState(false);

  const [inputIdentification, setInputIdentification] = useState("");
  const [errorIdentification, setErrorIdentification] = useState(false);

  const [inputPhone, setInputPhone] = useState("");
  const [errorPhone, setErrorPhone] = useState(false);

  const [showRemove, setShowRemove] = useState(false);
  const [showEdit, setShowEdit] = useState(false);

  const [openConfirmation, setOpenConfirmation] = useState(false);

  const [customerSelected, setCustomerSelected] = useState(null);

  // LOAD CSV 
  
  // State to store parsed data
  const [parsedData, setParsedData] = useState([]);
  //State to store table Column name
  const [tableRows, setTableRows] = useState([]);
  //State to store the values
  const [values, setValues] = useState([]);
  // Arrays for wrong data
  const [badIdentifications, setBadIdentifications] = useState([]);
  const [badPhones, setBadPhones] = useState([]);
  const [badIdentificationsFile, setBadIdentificationsFile] = useState("");
  const [badPhonesFile, setBadPhonesFile] = useState("");


  const inputRef = useRef();
  const openFileInput = () =>{
      inputRef.current.value=""; 
      inputRef.current.click(); // simulate click
  }

  // Context states
  const { currentColor, currentMode } = useStateContext();

  // Custom Button
  const NavButton = ({
    title,
    customFunc,
    icon,
    color,
    dotColor,
    visible = true,
  }) => {
    if (!visible) return <></>;
    return (
      <Tooltip content={title} placement="bottom-start">
        <button
          type="button"
          onClick={customFunc}
          style={{ color }}
          className="relative text-4xl rounded-full p-3 hover:bg-light-gray"
        >
          <span
            style={{ background: dotColor }}
            className="absolute inline-flex rounded-full h-2 w-2 right-2 top-2"
          />
          {icon}
        </button>
      </Tooltip>
    );
  };

  // Colums
  const columns = [
    { field: "customer_id", headerName: "ID", width: 70 },
    { field: "customer_first_name", headerName: "Nombre", width: 250 },
    { field: "customer_last_name", headerName: "Apellido", width: 250 },
    { field: "customer_identification", headerName: "Cédula/RUC", width: 200 },
    { field: "customer_phone", headerName: "Telefono", width: 200 },
    {
      field: "click_edit",
      headerName: "Modificar",
      width: 90,
      renderCell: (params) => {
        return (
          <div className="flex justify-center items-center w-full text-2xl">
            <button onClick={()=>handleClickEdit(params)}><MdModeEdit/></button>
          </div>
        );
      },
    },
    {
      field: "click_remove",
      headerName: "Eliminar",
      width: 90,
      renderCell: (params) => {
        return (
          <div className="flex justify-center items-center w-full text-2xl">
            <button onClick={()=>handleClickRemove(params)}><MdOutlineDelete/></button>
          </div>
        );
      },
    },
    // {
    //   field: "age",
    //   headerName: "Age",
    //   type: "number",
    //   width: 90,
    // },
    // {
    //   field: "fullName",
    //   headerName: "Full name",
    //   description: "This column has a value getter and is not sortable.",
    //   sortable: false,
    //   width: 160,
    //   valueGetter: (params) =>
    //     `${params.row.firstName || ""} ${params.row.lastName || ""}`,
    // },
  ];
  // *************** handlers

  const handleClickRemove = (params) => {
    setCustomerSelected({
      customer_id: params.row.customer_id,
      customer_first_name: params.row.customer_first_name,
      customer_last_name: params.row.customer_last_name,
    });
    
    setOpenConfirmation(true);
  };

  const handleRemoving=()=>{
    RemoveCustomer(customerSelected.customer_id, customerSelected)
    .then((response)=>{
      setMessageGlobalModal("El Socio se elimino correctamente.");
      setOpenGlobalModal(true);
    })
    .catch((error)=>{
      setMessageModal(
        `Error al intentar eliminar customer [${error.message}].`
        );
      setOpenGlobalModal(true);
    });

    handleCloseConfirmation();
  }
  
  const handleClickEdit = (params) => {
    setCustomerSelected({
      customer_id: params.row.customer_id,
      customer_first_name: params.row.customer_first_name,
      customer_last_name: params.row.customer_last_name,
      customer_identification: params.row.customer_identification,
      customer_phone: params.row.customer_phone,
    });
    // Load form dilog with custom data
    setInputFirstName(params.row.customer_first_name);
    setInputLastName(params.row.customer_last_name);
    setInputIdentification(params.row.customer_identification);
    setInputPhone(params.row.customer_phone);
    setOpenDialog(true);

  };

  const handleClickUpload = () => {
    window.alert("upload file");
  };

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setCustomerSelected(null);
    cleanFields();
    setOpenDialog(false);
  };

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handleInputFirstName = (event) => {
    const value = event.target.value;
    if (value.length < 1 || value.length > 100) {
      setInputFirstName(value.substring(0, 100));
      // setErrorCedula(true)
    } else {
      setErrorFirstName(false);
      setInputFirstName(value);
    }
  };

  const handleInputLastName = (event) => {
    const value = event.target.value;
    if (value.length < 1 || value.length > 100) {
      setInputLastName(value.substring(0, 100));
      // setErrorCedula(true)
    } else {
      setErrorLastName(false);
      setInputLastName(value);
    }
  };

  const handleInputIdentification = (event) => {
    const value = event.target.value;
    if (value.length < 10 || value.length > 13) {
      setInputIdentification(value.substring(0, 13));
      // setErrorCedula(true)
    } else {
      setErrorIdentification(false);
      setInputIdentification(value);
    }
  };

  const handleInputPhone = (event) => {
    const value = event.target.value;
    if (value.length < 9 || value.length > 10) {
      setInputPhone(value.substring(0, 10));
      // setErrorCedula(true)
    } else {
      setErrorPhone(false);
      setInputPhone(value);
    }
  };

  const handleCloseConfirmation=()=>{
    setCustomerSelected(null);
    setOpenConfirmation(false)
  }
  const handleOpenConfirmation=()=>{
    setOpenConfirmation(true)
  }

  const cleanFields=()=>{
    setInputFirstName("");
    setInputLastName("");
    setInputIdentification("");
    setInputPhone("");
  }

  
  const IDENTIFICATION_COL_INDEX=0;
  const IDENTIFICATION_COL_NAME="CEDULA"

  const LASTNAME_COL_INDEX=1;
  const LASTNAME_COL_NAME="NOMBRES";

  const FIRSTNAME_COL_INDEX=2;
  const FIRSTNAME_COL_NAME="APELLIDOS";
  
  const PHONE_COL_INDEX=3;
  const PHONE_COL_NAME="CELULAR";


  // Define CSV headers (key should match the header's key)
  const fileHeaders = [
    { label: `${IDENTIFICATION_COL_NAME}`, key: `${IDENTIFICATION_COL_NAME}` },
    { label: `${LASTNAME_COL_NAME}`, key: `${LASTNAME_COL_NAME}` },
    { label: `${FIRSTNAME_COL_NAME}`, key: `${FIRSTNAME_COL_NAME}` },
    { label: `${PHONE_COL_NAME}`, key: `${PHONE_COL_NAME}` },
  ];
  

  // Data Load 
  const handleFileChange = (e) => {
    Papa.parse(event.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        const rowsArray = [];
        const valuesArray = [];

        // Iterating data to get column name and their values
        results.data.map((d) => {
          rowsArray.push(Object.keys(d));
          valuesArray.push(Object.values(d));
        });


        if (rowsArray[0][IDENTIFICATION_COL_INDEX]!==IDENTIFICATION_COL_NAME 
            && rowsArray[0][LASTNAME_COL_INDEX]!==LASTNAME_COL_NAME 
            && rowsArray[0][FIRSTNAME_COL_INDEX]!==FIRSTNAME_COL_NAME 
            && rowsArray[0][PHONE_COL_INDEX]!==PHONE_COL_NAME
          ) {
          setMessageGlobalModal(`El archivo no tiene el formato correcto 
          (${IDENTIFICATION_COL_NAME}, ${LASTNAME_COL_NAME}, ${FIRSTNAME_COL_NAME}, ${PHONE_COL_NAME}).`);
          setOpenGlobalModal(true);
          return;
        }
        
        
        // Parsed Data Response in array format
        setParsedData(results.data);

        // Filtered Column Names
        setTableRows(rowsArray[0]);

        // Filtered Values
        setValues(valuesArray);
        
        let arrayPayload= [];
        let invalidIdentifications="";
        let invalidPhones="";
        let wrongIdentification = new Array;
        let wrongPhone = new Array;

        valuesArray.map((item)=>{

            // Valido la identificacion y el telefono

            if (
              !validator.ci( item[IDENTIFICATION_COL_INDEX].trim() ) &&
              !validator.ruc( item[IDENTIFICATION_COL_INDEX].trim() )
            ) {
              //invalidIdentifications += `${item[IDENTIFICATION_COL_INDEX]}, `;
              wrongIdentification.push(item);
            } else {
              if (
                !validator.cellphone( item[PHONE_COL_INDEX].trim() )
              ) {
                //invalidPhones += `${item[PHONE_COL_INDEX]}, `;
                wrongPhone.push(item)
              } else {
                // Validation Successfully
                const itemPayload={
                  customer_first_name: item[FIRSTNAME_COL_INDEX].trim(),
                  customer_last_name: item[LASTNAME_COL_INDEX].trim(),
                  customer_identification: item[IDENTIFICATION_COL_INDEX].trim(),
                  customer_phone: item[PHONE_COL_INDEX].trim()
                };
                arrayPayload.push(itemPayload);
              }
            }
            
        });
          
        // if (invalidIdentifications.length>0) invalidIdentifications = `Cedula/RUC invalidos: ${invalidIdentifications}`
        // if (invalidPhones.length>0) invalidPhones = `Celulares invalidos: ${invalidPhones}`
        // if (invalidIdentifications.length>0 || invalidPhones.length>0) {
        //   setMessageGlobalModal(
        //     `${invalidIdentifications}\r\n ${invalidPhones}`
        //   );
        //   setOpenGlobalModal(true);
        //   return;
        // }

        
        if (wrongIdentification.length>0 || wrongPhone.length>0) {

          setBadIdentifications(wrongIdentification);
          setBadPhones(wrongPhone);

          setMessageGlobalModal(
            `Hay ${wrongIdentification.length} indentificaciones invalidas \r\n Hay ${wrongPhone.length} telefonos invalidos`
          );
          setOpenGlobalModal(true);

          // Only return if identifications have invalids
          if (wrongIdentification.length>0) return;

        }

        // setBadIdentifications([]);
        // setBadPhones([]);
        
        SaveManyCustomer({arrayPayload})
        .then((res) => {
          setMessageGlobalModal("El se cargo correctamente.");
          setOpenGlobalModal(true);

          //clean fields
          cleanFields();
        })
        .catch((error) => {
          setMessageGlobalModal(
            `Error al intentar grabar datos del archivo [${error.message}].\r\nRevise por favor.`
          );
          setOpenGlobalModal(true);
        });


      },
    });

  };

  /* BACKDROP */
  const handleBackdropClick = (e) => {
    //these fail to keep the modal open
    e.stopPropagation();
    return false;
  };

  return (
    <div className="m-2 md:m-10 p-2 md:p-10 bg-white bg-opacity-80 rounded-3xl ">
      <div className="flex flex-row justify-between items-center">
        <Header category="Página" title="Socios" />
        <div>
          <NavButton
            title="Agregar Socio"
            customFunc={() => handleClickOpen()}
            color={currentColor}
            icon={<MdAdd />}
          />
          <input onChange={handleFileChange} ref={inputRef} type="file" id="upload" style={{display: "none"}} />
          <NavButton
            title="Cargar Socios"
            customFunc={() => openFileInput()}
            color={currentColor}
            icon={<MdUploadFile />}
          />
          {badIdentifications.length>0 &&
            <CSVLink
            headers={fileHeaders}
            data={badIdentifications}
            filename="badIdentificationsFile.csv"
            target="_blank"
          >
            <HiOutlineIdentification className=" text-red-600" />
          </CSVLink>
          }
          {badPhones.length>0 &&
            <CSVLink
            headers={fileHeaders}
            data={badPhones}
            filename="badPhonesFile.csv"
            target="_blank"
          >
            <MdPhoneIphone className=" text-red-600"/>
          </CSVLink>
          }
        </div>
      </div>
      <div>
        <div>
          {/* DIALOG CONFIRMATION  */}
          <Dialog
            open={openConfirmation}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleCloseConfirmation}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle>{`Eliminar a ${customerSelected?.customer_first_name}, ${customerSelected?.customer_last_name} ? `}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                Desea eliminar este usuario de la plataforma ?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseConfirmation}>Cancelar</Button>
              <Button onClick={handleRemoving}>Aceptar</Button>
            </DialogActions>
          </Dialog>

          {/* *** FORM DIALOG *** */}
          <Dialog
            sx={{ "& .MuiDialog-paper": { width: "100%", maxHeight: 500 } }}
            maxWidth="xs"
            {...other}
            open={openDialog}
            onClick={(e) => e.stopPropagation()}
            disableEscapeKeyDown={true}
            onClose={handleClose}
            PaperProps={{
              component: "form",
              onSubmit: (event) => {
                event.preventDefault();
                const formData = new FormData(event.currentTarget);
                const formJson = Object.fromEntries(formData.entries());

                // Validation
                const _firstName = formJson.firstName;
                const _lastName = formJson.lastName;
                const _identification = formJson.identification;
                const _phone = formJson.phone;

                // Identification validation
                if (
                  !validator.ci(_identification) &&
                  !validator.ruc(_identification)
                ) {
                  setMessageModal(
                    `Cedula/RUC ${_identification} es invalida.\r\nRevise por favor.`
                  );
                  setOpenModal(true);
                  return;
                }

                // Identification validation
                if (!validator.cellphone(_phone)) {
                  setMessageModal(
                    `Celular ${_phone} es invalido.\r\nRevise por favor.`
                  );
                  setOpenModal(true);
                  return;
                }

                // Verify if the identification exists
                // ** this is doing in database

                // Enviar al ENDPOINT y espeerar respuesta antes de cerrar
                let payload;
                if (!customerSelected) {
                  payload={
                    customerFirstName: _firstName,
                    customerLastName: _lastName,
                    customerIdentification: _identification,
                    customerPhone: _phone,
                  };
                  SaveCustomer(payload)
                    .then((res) => {
                      setMessageGlobalModal("El Socio se grabo correctamente.");
                      setOpenGlobalModal(true);
                      handleClose();
  
                      //clean fields
                      cleanFields();
                      handleClose();
                    })
                    .catch((error) => {
                      setMessageModal(
                        `Error al intentar grabar socio [${error.message}].\r\nRevise por favor.`
                      );
                      setOpenModal(true);
                    });

                  } else {
                  payload={
                    customerId: customerSelected.customer_id,
                    customerFirstName: _firstName,
                    customerLastName: _lastName,
                    customerIdentification: _identification,
                    customerPhone: _phone,
                  };
                  UpdateCustomer(payload)
                    .then((res) => {
                      setMessageGlobalModal("El Socio se actualizo correctamente.");
                      setOpenGlobalModal(true);
                      handleClose();
  
                      //clean fields
                      cleanFields();
                      handleClose();
                    })
                    .catch((error) => {
                      setMessageGlobalModal(
                        `Error al intentar actualizar socio [${error.message}].\r\nRevise por favor.`
                      );
                      setOpenGlobalModal(true);
                    });
                }
                resetTable();

              },
            }}
          >
            <DialogTitle>{(!customerSelected) ? 'AGREGAR SOCIO' : 'MODIFICAR SOCIO'}</DialogTitle>
            <DialogContent>
              <div>
                {/* MODAL */}
                <Modal
                  keepMounted
                  open={openModal}
                  onClose={handleCloseModal}
                  aria-labelledby="keep-mounted-modal-title"
                  aria-describedby="keep-mounted-modal-description"
                >
                  <Box sx={style}>
                    <Typography
                      id="keep-mounted-modal-title"
                      variant="h6"
                      component="h2"
                    >
                      {messageModal}
                    </Typography>
                  </Box>
                </Modal>
              </div>
              {/* <DialogContentText>
                NUEVO SOCIO
              </DialogContentText> */}
              <TextField
                autoFocus
                required
                margin="dense"
                id="firstName"
                name="firstName"
                label="Nombre"
                type="text"
                fullWidth
                variant="standard"
                value={inputFirstName}
                onChangeCapture={handleInputFirstName}
                error={errorFirstName}
                helperText="Debe ingresar un nombre valido."
              />
              <TextField
                required
                margin="dense"
                id="lastName"
                name="lastName"
                label="Apellido"
                type="text"
                fullWidth
                variant="standard"
                value={inputLastName}
                onChangeCapture={handleInputLastName}
                error={errorLastName}
                helperText="Debe ingresar un apellido valido."
              />
              <TextField
                required
                margin="dense"
                id="identification"
                name="identification"
                label="Cedual/RUC"
                type="number"
                fullWidth
                variant="standard"
                value={inputIdentification}
                onChangeCapture={handleInputIdentification}
                error={errorIdentification}
                helperText="Debe ingresar una Cedula/RUC valido."
              />
              <TextField
                required
                margin="dense"
                id="phone"
                name="phone"
                label="Celular"
                type="number"
                fullWidth
                variant="standard"
                value={inputPhone}
                onChangeCapture={handleInputPhone}
                error={errorPhone}
                helperText="Debe ingresar un celular valido."
              />
            </DialogContent>
            <DialogActions>
              <Button variant="outlined" color="error" onClick={handleClose}>
                Cancelar
              </Button>
              <Button type="submit" variant="contained" endIcon={<SendIcon />}>
                Grabar
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        {data && (
          <Box sx={{ width: "100%" }}>
            <DataGrid
              key={seed}
              rows={data}
              columns={columns}
              getRowId={(row) => row?.customer_id}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 25 },
                },
              }}
              pageSizeOptions={[25, 50, 100]}
              // checkboxSelection
            />
          </Box>
        )}
      </div>
    </div>
  );
}

export default Customers;
