import React from "react";
// PDF
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer";

import logoMinga from "./assets/logo-minga.jpg";

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#E4E4E4",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
    fontSize: "12",
  },
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    fontFamily: "Oswald",
  },
  author: {
    fontSize: 12,
    textAlign: "center",
    marginBottom: 20,
  },
  subtitle: {
    textAlign: "center",
    fontSize: 18,
    margin: 20,
    fontFamily: "Oswald",
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: "justify",
    fontFamily: "Oswald",
  },
  image: {
    height: 41,
    width: 141,
    marginVertical: 0,
    marginHorizontal: 5,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});

// Register fonts
Font.register({
  family: "Oswald",
  src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
});

const TicketsPDF = (props) => {
  // console.log(props?.operations);
  const formatDate = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    weekday: "long",
    hour: "2-digit",
    hour12: false,
    minute: "2-digit",
    second: "2-digit",
  };

  return (
    <Document>
      <Page size="A4" style={styles.body}>
        <Text style={styles.header} fixed>
          ~ Cooperativa de Ahorro y Credito MINGA Ltda. ~
        </Text>
        <Image
          style={styles.image}
          src={`${window.location.origin + "/images/logo-minga.jpg"}`}
        />
        <Text style={styles.title}>
          Listado de Boletos por Transacci&otilde;n
        </Text>
        <Text style={styles.author}>
          Corte: {new Date().toLocaleDateString("es-EC", formatDate)}
        </Text>
        <Text style={styles.subtitle}>
          Socio(a): {props.operations?.customer_first_name},{" "}
          {props.operations?.customer_last_name}
          &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;Ci/Ruc:{" "}
          {props.operations?.customer_identification}
        </Text>

        {props.operations?.customer_operation.map((item) => (
          <View key={item.customer_operation_reference} style={styles.text}>
            <Text>
              Fecha:&nbsp;&nbsp;
              {new Date(item.customer_operation_created).toLocaleDateString(
                "es-EC",
                formatDate
              )}
            </Text>
            <Text>
              No. Comprobante:&nbsp;&nbsp;{item.customer_operation_reference}
              &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;Agencia:&nbsp;&nbsp;
              {item.hierarchy?.hierarchy_name}
            </Text>
            <Text>
              BOLETOS:&nbsp;&nbsp;
              {item.ticket
                .map((t) => `#${String(t.ticket_id).padStart(7, "0")}`)
                .join(", ")}
            </Text>
          </View>
        ))}
        {/* <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        /> */}
      </Page>
    </Document>
  );
};

export default TicketsPDF;
