import useSWR from "swr";
import { getter, poster, fetcher, fetcherPayload } from "./index";

const API_ENDPOINT = "/operation";

const swrOptions = {
  revalidateOnReconnect: true,
  revalidateOnFocus: true,
  dedupingInterval: 30000, // you can only refetched after x milisecons
  refreshInterval: 180000, // refetch data every x miliseconds
};

export const SaveOperation = async (payload) => {
  try {
    // const savedOperation = poster(API_ENDPOINT, payload);
    return await poster(API_ENDPOINT, payload);

    // const payloadGetTickets = {
    //   userId: savedOperation.data.data.user_id,
    //   hierarchyId: savedOperation.data.data.hierarchy_id,
    //   customerId: savedOperation.data.data.customer_id,
    //   lotteryId: savedOperation.data.data.lottery_id,
    //   customerOperationReference:
    //     savedOperation.data.data.customer_operation_reference,
    // };
    // const savedTickets = await fetcherPayload(API_ENDPOINT, payloadGetTickets);
    // console.log(JSON.stringify(savedTickets.ticket.length));

    // return await {
    //     status: 200,
    //     statusText: "OK",
    //     data:{
    //         operation: savedOperation,
    //         // tickets: savedTickets
    //     }
    // };
  } catch (error) {
    throw new Error(error);
  }
};

//userId, hierarchyId, customerId, lotteryId
export const GetOperation = (payload) => {
  try {
    return useSWR({ url: API_ENDPOINT, payload }, fetcherPayload, swrOptions);
  } catch (error) {
    throw new Error(error);
  }
};

//userId, hierarchyId, customerId, lotteryId
export const ResendOperation = async (payload) => {
  try {
    return await poster(`${API_ENDPOINT}/resend`, payload);
  } catch (error) {
    throw new Error(error);
  }
};

export const GetOperationExtended = async (payload) => {
  try {
    return await poster(`${API_ENDPOINT}/all/extended`, payload);
  } catch (error) {
    throw new Error(error);
  }
};

export const GetOperationExtendedHierarchy = async (payload) => {
  try {
    return await poster(`${API_ENDPOINT}/all/extended/hierarchy`, payload);
  } catch (error) {
    throw new Error(error);
  }
};

export const GetOperationExtendedUser = async (payload) => {
  try {
    return await poster(`${API_ENDPOINT}/all/extended/user`, payload);
  } catch (error) {
    throw new Error(error);
  }
};

export default {
  SaveOperation,
  GetOperation,
  GetOperationExtended,
  ResendOperation,
  GetOperationExtendedHierarchy,
  GetOperationExtendedUser,
};
