import React, { useState, useEffect, useRef } from "react";
import Header from "../components/Header";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { CustomButton } from "../components/CustomButton";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { FaWhatsapp } from "react-icons/fa6";
import { AiOutlineFileSearch } from "react-icons/ai";

// Data
import { GetOperationExtended, ResendOperation } from "../api/operation";
import { FilterData } from "../components";

// Contexts
import { useStateContext } from "../contexts/ContextProvider";
import { Today } from "@mui/icons-material";
import { useStaticPicker } from "@mui/x-date-pickers/internals";
import { getLocalISOTime } from "../utils/formatter";

const Operations = () => {
  const today = new Date();
  const [filter, setFilter] = useState(null);
  const [seed, setSeed] = useState(1);
  const [dataFetched, setDataFetched] = useState(null);
  const [dateFrom, setDateFrom] = useState(dayjs(today.toISOString()));
  const [dateTo, setDateTo] = useState(dayjs(today.toISOString()));
  const [searching, setSearching] = useState(false);

  // Global Context
  const {
    setOpenGlobalModal,
    setMessageGlobalModal,
    setOpenGlobalModalError,
    setMessageGlobalModalError,
  } = useStateContext();

  const resetTable = () => {
    setSeed(Math.random());
  };

  useEffect(() => {
    (async () => {
      await GetOperationExtended({
        dateFrom: filter?.from,
        dateTo: filter?.to,
        lotteryId: filter?.lotteryId,
      })
        .then((data) => setDataFetched(data))
        .catch((error) => console.log(error));
      return async () => {
        resetTable();
      };
    })();
  }, [seed]);

  //Toolbar
  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  };

  // Colums
  const columns = [
    { field: "user_id", type: "number", headerName: "USERID", width: 0 },
    {
      field: "hierarchy_id",
      type: "number",
      headerName: "HIERARCHYID",
      width: 0,
    },
    { field: "lottery_id", type: "number", headerName: "LOTTERYID", width: 0 },
    {
      field: "customer_id",
      type: "number",
      headerName: "CUSTOMERID",
      width: 0,
    },
    {
      field: "userName",
      headerName: "USUARIO",
      description: "This column has a value getter and is not sortable.",
      sortable: true,
      width: 180,
      valueGetter: (params) => `${params.row.user?.user_name}`,
    },
    {
      field: "hierarchyName",
      headerName: "AGENCIA",
      description: "This column has a value getter and is not sortable.",
      sortable: true,
      width: 150,
      valueGetter: (params) => `${params.row.hierarchy?.hierarchy_name}`,
    },
    {
      field: "lotteryName",
      headerName: "SORTEO",
      description: "This column has a value getter and is not sortable.",
      sortable: true,
      width: 200,
      valueGetter: (params) => `${params.row.lottery?.lottery_name}`,
    },
    {
      field: "customerName",
      headerName: "SOCIO",
      description: "This column has a value getter and is not sortable.",
      sortable: true,
      width: 300,
      valueGetter: (params) =>
        `${params.row.customer?.customer_first_name}, ${params.row.customer?.customer_last_name}`,
    },
    {
      field: "customerPhone",
      headerName: "CELULAR",
      description: "This column has a value getter and is not sortable.",
      sortable: true,
      width: 150,
      valueGetter: (params) => `${params.row.customer?.customer_phone}`,
    },
    {
      field: "customer_operation_reference",
      headerName: "COMPROBANTE",
      width: 230,
    },
    {
      field: "operationDate",
      type: "datetime",
      headerName: "FECHA",
      description: "This column has a value getter and is not sortable.",
      sortable: true,
      width: 151,
      valueGetter: (params) =>
        `${new Date(params.row.customer_operation_created)
          .toISOString()
          .replace("T", " ")}`,
    },
    {
      field: "customer_operation_amount",
      type: "number",
      headerName: "MONTO $",
      width: 100,
    },
    { field: "message_status", headerName: "MENSAJE", width: 80 },
    {
      field: "tickets",
      type: "number",
      headerName: "BOLETOS",
      description: "This column has a value getter and is not sortable.",
      sortable: true,
      width: 100,
      valueGetter: (params) => `${params.row._count?.ticket}`,
    },
    {
      field: "click_send",
      headerName: "REENVIAR",
      width: 100,
      renderCell: (params) => {
        if (
          params.row.customer_operation_status == "Q" ||
          params.row.message_status == "queued" ||
          params.row.message_status == "failed" ||
          params.row.message_status == "undelivered" ||
          params.row.message_status == "sent"
        ) {
          return (
            <div className="flex justify-center items-center w-full text-2xl">
              <button onClick={() => handleClickResend(params)}>
                <FaWhatsapp style={{ color: "green" }} />
              </button>
            </div>
          );
        }
      },
    },
    // // Base field value from expresion
    // { field: "user_permissions", valueGetter: (params) => {
    //     return joinPermissions(params);
    //   }, headerName: "Permisos", width: 500
    // },

    // {
    //   field: "click_remove",
    //   headerName: "Eliminar",
    //   width: 65,
    //   renderCell: (params) => {
    //     return (
    //       <div className="flex justify-center items-center w-full text-2xl">
    //         <button onClick={() => handleClickRemove(params)}>
    //           <MdOutlineDelete />
    //         </button>
    //       </div>
    //     );
    //   },
    // },
    // // {
    // //   field: "age",
    // //   headerName: "Age",
    // //   type: "number",
    // //   width: 90,
    // // },
    // // {
    // //   field: "fullName",
    // //   headerName: "Full name",
    // //   description: "This column has a value getter and is not sortable.",
    // //   sortable: false,
    // //   width: 160,
    // //   valueGetter: (params) =>
    // //     `${params.row.firstName || ""} ${params.row.lastName || ""}`,
    // // },
  ];

  const handleClickResend = async (params) => {
    await ResendOperation({
      user_id: params.row.user_id,
      hierarchy_id: params.row.hierarchy_id,
      lottery_id: params.row.lottery_id,
      customer_id: params.row.customer_id,
      customer_operation_reference: params.row.customer_operation_reference,
    })
      .then((res) => {
        //console.log(res);
        if (res) {
          setMessageGlobalModal("mensaje se re-envio correctamente.");
          setOpenGlobalModal(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCallbackFilter = async (filterParams) => {
    if (!filterParams.lotteryId) return;
    await setFilter(filterParams);
    await resetTable();
  };

  return (
    <div className="m-2 md:m-10 p-2 md:p-10 bg-white bg-opacity-80 rounded-3xl ">
      <Header category="Página" title="Operaciones" />
      <div className="flex flex-row justify-between items-center">
        <div className="flex-1 flex-col">
          <div>
            <FilterData parentCallback={handleCallbackFilter} />
            {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DateTimePicker"]}>
                <div className="flex flex-row justify-end">
                  <DateTimePicker
                    clearable
                    ampm={false}
                    label="Fecha desde"
                    value={dateFrom}
                    onChange={(newValue) => setDateFrom(newValue)}
                  />
                  &nbsp;&nbsp;&nbsp;
                  <DateTimePicker
                    clearable
                    ampm={false}
                    label="Fecha hasta"
                    value={dateTo}
                    onChange={(newValue) => setDateTo(newValue)}
                  />
                  &nbsp;&nbsp;&nbsp;
                  <span style={{ fontSize: "48px" }}>
                    <AiOutlineFileSearch onClick={handleClickSearch} />
                  </span>
                </div>
              </DemoContainer>
            </LocalizationProvider> */}
          </div>
          <div>
            {dataFetched && (
              <Box sx={{ width: "100%" }}>
                <DataGrid
                  key={seed}
                  rows={dataFetched}
                  columns={columns}
                  slots={{
                    toolbar: CustomToolbar,
                  }}
                  getRowId={(row) =>
                    row?.user_id +
                    row?.hierarchy_id +
                    row?.lottery_id +
                    row?.customer_id +
                    row?.customer_operation_reference
                  }
                  initialState={{
                    columns: {
                      columnVisibilityModel: {
                        user_id: false,
                        hierarchy_id: false,
                        lottery_id: false,
                        customer_id: false,
                      },
                    },
                    pagination: {
                      paginationModel: { page: 0, pageSize: 25 },
                    },
                  }}
                  pageSizeOptions={[25, 50, 100]}
                />
              </Box>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Operations;
