import React, {useState} from 'react'

import { useNavigate } from "react-router-dom";
import { MdOutlineCancel } from "react-icons/md";
import { RiMailSendLine } from "react-icons/ri";

import Box from '@mui/material/Box';
import Input from '@mui/material/Input';

import { Button } from ".";
import { userProfileData } from "../data/dummy";
import { useStateContext } from "../contexts/ContextProvider";
import avatar from "../data/avatar.jpg";
import noProfile from '../data/no-profile.png';

// Data
import { ForgotAction } from "../api/auth";



const UserProfile = (props) => {
  const { initialState, setIsClicked, currentColor, currentMode, token, setToken, userLogged, setUserLogged, setOpenGlobalModal, setMessageGlobalModal } = useStateContext();
  const [ forgotPassword, setForgotPassword] = useState(false)
  const [ inputForgotEmail, setInputForgotEmail] = useState("")

  const user=JSON.parse(userLogged);
  
  const navigate = useNavigate();
  const ariaLabel = { 'aria-label': 'description' };

  const handleForgotPassword =async ()=>{
    // window.alert(inputForgotEmail);

    if (inputForgotEmail.length===0 && !inputForgotEmail.includes("@")){
      setMessageGlobalModal("Correo inválido.")
      setOpenGlobalModal(true);
      return;
    }

    const payload={
      email: inputForgotEmail,
    };

    await ForgotAction(payload)
    .then((res) => {
      if (res) {
        setMessageGlobalModal("Se le envio un mensaje para la recuperación/cambio de su clave.")
        setOpenGlobalModal(true);
      } else {
        setMessageGlobalModal("El correo es incorrecto.")
        setOpenGlobalModal(true);        
      }

    })
    .catch((error) => {
      setMessageGlobalModal(`Error al intentar recuperar clave [${error.message}].`);
      setOpenGlobalModal(true);
    });

    setForgotPassword(false);

  }

  const handleInputForgotEmail =(e)=>{
    setInputForgotEmail(e.target.value);
  }

  return (
    <div className="nav-item absolute right-1 top-16 bg-white dark:bg-[#42464D] p-8 rounded-lg w-96">
      <div className="flex justify-between items-center">
        <p className="font-semibold text-lg dark:text-gray-200">User Profile</p>
        <Button
          icon={<MdOutlineCancel />}
          color="rgb(153, 171, 180)"
          bgHoverColor="light-gray"
          size="2xl"
          borderRadius="50%"
          customFunc={()=>setIsClicked(initialState)}
        />
      </div>
      {token && (
        <div className="flex gap-5 items-center mt-6 border-color border-b-1 pb-6">
          <img
            className="rounded-full h-24 w-24"
            src={!user?.user_image_url ? noProfile : user?.user_image_url}
            alt="user-profile"
          />
          <div>
            <p className="font-semibold text-xl dark:text-gray-200">
              {" "}
              {user?.user_name}{" "}
            </p>
            <p className="text-gray-500 text-sm dark:text-gray-400">
              {" "}
              {user?.hierarchy_role_name}{" "}
            </p>
            <p className="text-gray-500 text-sm font-semibold dark:text-gray-400">
              {" "}
              {user?.user_email}{" "}
            </p>
          </div>
        </div>
      )}
      <div className="mt-5">
        {token && (
          <>
            {/* <div>
              {userProfileData.map((item, index) => (
                <div
                  key={index}
                  className="flex gap-5 border-b-1 border-color p-4 hover:bg-light-gray cursor-pointer  dark:hover:bg-[#42464D]"
                >
                  <button
                    type="button"
                    style={{
                      color: item.iconColor,
                      backgroundColor: item.iconBg,
                    }}
                    className=" text-xl rounded-lg p-3 hover:bg-light-gray"
                  >
                    {item.icon}
                  </button>

                  <div>
                    <p className="font-semibold dark:text-gray-200 ">
                      {item.title}
                    </p>
                    <p className="text-gray-500 text-sm dark:text-gray-400">
                      {" "}
                      {item.desc}{" "}
                    </p>
                  </div>
                </div>
              ))}
            </div> */}
            <Button
              color="white"
              bgColor={currentColor}
              text="Logout"
              borderRadius="10px"
              width="full"
              customFunc={() => {
                setToken(null);
                setUserLogged(null);
                setIsClicked(initialState);
                navigate("/", {replace: true});
              } }
            />
            {!forgotPassword &&
              (
              <div className=' flex p-5 w-full justify-center items-center dark:text-white text-black'>
                <span><a href='#' onClick={()=>setForgotPassword(true)}>Cambiar mi clave</a></span>           
              </div>

              )              
            }
            {forgotPassword &&
              (
                <div className=' flex flex-row mt-5 w-full justify-center items-center dark:text-white text-black'>
                  <Box
                    component="form"
                    sx={{
                      '& > :not(style)': { m: 1, width: '30ch' },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                      <Input type='email'
                      style={currentMode ==='Dark' ? {  color:'white'  } : ({  color:'black'  } ) } 
                      id='email' name='email' value={inputForgotEmail} onChange={handleInputForgotEmail}
                      placeholder="Su correo para cambiar clave aquí" inputProps={ariaLabel} 
                      />
                  </Box>

                  <button
                    type="button"
                    onClick={handleForgotPassword}
                    style={currentMode ==='Dark' ? {  color:'white'  } : ({  color:'black'  } ) }
                  >
                    <RiMailSendLine  sx={ { color: (currentMode ==='Dark' ? 'white[500]' : 'black[500]'), fontSize: "large"  } } />
                  </button>
                </div>
              )
            }
          </>
          
        )}
        {!token &&
          (
            <>
              <Button
                color="white"
                bgColor={currentColor}
                text="Login"
                borderRadius="10px"
                width="full"
                customFunc={()=>{
                  setIsClicked(initialState);
                  navigate("/login", {replace: true});
                }}
              />
              {!forgotPassword &&
                (
                <div className=' flex p-5 w-full justify-center items-center dark:text-white text-black'>
                  <span><a href='#' onClick={()=>setForgotPassword(true)}>Si olvido su clave presione aquí</a></span>           
                </div>

                )              
              }
              {forgotPassword &&
                (
                  <div className=' flex flex-row mt-5 w-full justify-center items-center dark:text-white text-black'>
                    <Box
                      component="form"
                      sx={{
                        '& > :not(style)': { m: 1, width: '30ch' },
                      }}
                      noValidate
                      autoComplete="off"
                    >
                       <Input
                        style={currentMode ==='Dark' ? {  color:'white'  } : ({  color:'black'  } ) } 
                        id='email' name='email' value={inputForgotEmail} onChange={handleInputForgotEmail}
                        placeholder="Su correo para recuperar clave aquí" inputProps={ariaLabel} 
                        />
                    </Box>

                    <button
                      type="button"
                      onClick={handleForgotPassword}
                      style={currentMode ==='Dark' ? {  color:'white'  } : ({  color:'black'  } ) }
                    >
                      <RiMailSendLine  sx={ { color: (currentMode ==='Dark' ? 'white[500]' : 'black[500]'), fontSize: "large"  } } />
                    </button>
                  </div>
                )
              }

            </>
          )
        }
      </div>
    </div>
  );
};

export default UserProfile;
