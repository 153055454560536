import React from 'react'

import UnauthorizedImage from "../assets/Unauthorized.png";
import "./Unauthorized.css"

const Unauthorized = () => {
    const _unauthorizedImage = "../assets/Unauthorized.png";

  return (
    <div className="banner-container items-center w-full"> 
        <div className=' w-1/4'>
            <img src={UnauthorizedImage} />
        </div>
    </div>
  )
}

export default Unauthorized