import React, { useState, useEffect, useRef } from "react";
import Header from "../components/Header";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import {
  MdAdd,
  MdCreate,
  MdRemove,
  MdOutlineDelete,
  MdModeEdit,
  MdFileUpload,
  MdUploadFile,
  MdUpload,
  MdAccountTree,
} from "react-icons/md";


import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import SendIcon from "@mui/icons-material/Send";
import Modal from "@mui/material/Modal";
import Slide from "@mui/material/Slide";

import validator from "ecuador-validator";
import Papa from "papaparse";

import { DataGrid } from "@mui/x-data-grid";

// DropDown
import {HierarchySelect} from '../components'

// DropDown
import {RoleSelect} from '../components'

// Data
import {
  PreGetUsers,
  GetUsers,
  SaveUser,
  RemoveUser,
  UpdateUser,
  SaveManyUsers,
} from "../api/users";

// Contexts
import { useStateContext } from "../contexts/ContextProvider";

// UPLOAD
import axios from "axios";
import { api, configForUpload } from "../api/index";

const MAX_IMAGE_SIZE = process.env.REACT_APP_MAX_IMAGE_SIZE;
const MAX_VIDEO_SIZE = process.env.REACT_APP_MAX_VIDEO_SIZE;
const MAX_IMAGE_WIDTH = process.env.REACT_APP_MAX_IMAGE_WIDTH;
const MAX_IMAGE_HEIGHT = process.env.REACT_APP_MAX_IMAGE_HEIGHT;


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

// Transitions
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// load CSV
const allowedExtensions = ["csv"];

PreGetUsers();

//************************************************************************************************************ */
export function Users(props) {
  const { data, error, isLoading, isValidating } = GetUsers();

  const [seed, setSeed] = useState(1);
  const resetTable = () => {
    setSeed(Math.random());
  };

  // Global Context
  const {
    setOpenGlobalModal,
    setMessageGlobalModal,
    setOpenGlobalModalError,
    setMessageGlobalModalError,
  } = useStateContext();

  const { onClose, value: valueProp, open, ...other } = props;

  const [openDialog, setOpenDialog] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const [messageModal, setMessageModal] = useState("");

  const [inputHierarchyId, setInputHierarchyId] = useState("");
  const [errorHierarchyId, setErrorHierarchyId] = useState(false);

  const [inputUserName, setInputUserName] = useState("");
  const [errorUserName, setErrorUserName] = useState(false);

  const [inputUserPassword, setInputUserPassword] = useState("");
  const [errorUserPassword, setErrorUserPassword] = useState(false);

  const [inputEmail, setInputEmail] = useState("");
  const [errorEmail, setErrorEmail] = useState(false);

  const [inputPhone, setInputPhone] = useState("");
  const [errorPhone, setErrorPhone] = useState(false);

  const [errorImageUrl, setErrorImageUrl] = useState(false);
  const [inputImageUrl, setInputImageUrl] = useState("");
  const [errorImageUrlMessage, setErrorImageUrlMessage] = useState(
    "Ingrese una imagen valida."
  );

  const [inputRoleIds, setInputRoleIds] = useState([])
  const [inputRoleNames, setInputRoleNames] = useState([])

  const [showRemove, setShowRemove] = useState(false);
  const [showEdit, setShowEdit] = useState(false);

  const [openConfirmation, setOpenConfirmation] = useState(false);

  const [userSelected, setUserSelected] = useState(null);

  // UPLOAD IMAGE
const [selectedImageFile, setSelectedImageFile] = useState(null);
const inputImageRef = useRef();

const openFileImageInput = () => {
  inputImageRef.current.value = "";
  inputImageRef.current.click(); // simulate click on file selected
};

const handleOnChangeImageFile = (event) => {
  let imageFile = event.target.files[0];
  // console.log(imageFile);

  if (
    !imageFile.name ||
    imageFile.name === "" ||
    imageFile.type !== "image/png"
  ) {
    setErrorImageUrlMessage(
      "El archivo no es una imagen valida debe ser tipo jpg o png"
    );
    setErrorImageUrl(true);
    setInputImageUrl("");
    return;
  }

  const img = new Image();
  // Set the src attribute to the selected image
  img.src = URL.createObjectURL(imageFile);
  // Wait for the image to load
  img.onload = function () {
    // Access the width and height
    const imageWidth = img.width;
    const imageHeight = img.height;

    // Now you can use imageWidth and imageHeight as needed
    // console.log(`Image dimensions: ${imageWidth} x ${imageHeight} MAX_IMAGE_WIDTH: ${MAX_IMAGE_WIDTH}, MAX_IMAGE_SIZE: ${MAX_IMAGE_SIZE}`);

    if (imageWidth > MAX_IMAGE_WIDTH || imageHeight > MAX_IMAGE_HEIGHT) {
      setErrorImageUrlMessage(
        `La imagen debe medir maximo ${MAX_IMAGE_WIDTH}x${MAX_IMAGE_HEIGHT} pixels`
      );
      setErrorImageUrl(true);
      setInputImageUrl("");
      return;
    }

    if (imageFile.size > MAX_IMAGE_SIZE) {
      setErrorImageUrlMessage(
        `La imagen debe pesar maximo ${
          MAX_IMAGE_SIZE / 1024 / 1024
        } Mega Bytes`
      );
      setErrorImageUrl(true);
      setInputImageUrl("");
      return;
    }
  };

  setErrorImageUrlMessage("Seleccione una imagen valida.");
  setErrorImageUrl(false);
  setSelectedImageFile(imageFile);
  setInputImageUrl(imageFile.name);
};


  // LOAD CSV

  // State to store parsed data
  const [parsedData, setParsedData] = useState([]);

  //State to store table Column name
  const [tableRows, setTableRows] = useState([]);

  //State to store the values
  const [values, setValues] = useState([]);

  const inputRef = useRef();
  const openFileInput = () => {
    inputRef.current.value = "";
    inputRef.current.click(); // simulate click
  };

  // Context states
  const { currentColor, currentMode } = useStateContext();

  const joinRoles = (params) =>{
    return `${params.row.hierarchy_role.map((hierarchyRole) => `${hierarchyRole.role.role_name}`).join(' ● ')}`
  }

  const splitRoles = (params) =>{
    return params.row.hierarchy_role.map((hierarchyRole) => `${hierarchyRole.role.role_name}`).join(',').split(',');
  }


  // Custom Button
  const NavButton = ({
    title,
    customFunc,
    icon,
    color,
    dotColor,
    visible = true,
  }) => {
    if (!visible) return <></>;
    return (
      <Tooltip content={title} placement="bottom-start">
        <button
          type="button"
          onClick={customFunc}
          style={{ color }}
          className="relative text-4xl rounded-full p-3 hover:bg-light-gray"
        >
          <span
            style={{ background: dotColor }}
            className="absolute inline-flex rounded-full h-2 w-2 right-2 top-2"
          />
          {icon}
        </button>
      </Tooltip>
    );
  };

  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    hierarchy_id: false,
  });

  // Colums
  const columns = [
    { field: "user_id", headerName: "ID", width: 70 },
    { field: "hierarchy_id", headerName: "Padre ID"},

    // Base field value from expresion
    { field: "hierarchy_name", valueGetter: (params) => {
        return `${params.row.hierarchy.hierarchy_name}`;
      }, headerName: "Jerarquia", width: 250 },

    { field: "user_name", headerName: "Usuario", width: 150 },
    { field: "user_email", headerName: "Correo", width: 250 },
    { field: "user_phone", headerName: "Telefono", width: 100 },
    { field: "user_image_url", headerName: "Imagen", width: 250 },

    // Base field value from expresion
    { field: "user_roles", valueGetter: (params) => {
      return joinRoles(params);
      }, headerName: "Roles", width: 300 
    },

    {
      field: "click_edit",
      headerName: "Modificar",
      width: 65,
      renderCell: (params) => {
        return (
          <div className="flex justify-center items-center w-full text-2xl">
            <button onClick={() => handleClickEdit(params)}>
              <MdModeEdit />
            </button>
          </div>
        );
      },
    },
    {
      field: "click_remove",
      headerName: "Eliminar",
      width: 65,
      renderCell: (params) => {
        return (
          <div className="flex justify-center items-center w-full text-2xl">
            <button onClick={() => handleClickRemove(params)}>
              <MdOutlineDelete />
            </button>
          </div>
        );
      },
    },

    // {
    //   field: "age",
    //   headerName: "Age",
    //   type: "number",
    //   width: 90,
    // },
    // {
    //   field: "fullName",
    //   headerName: "Full name",
    //   description: "This column has a value getter and is not sortable.",
    //   sortable: false,
    //   width: 160,
    //   valueGetter: (params) =>
    //     `${params.row.firstName || ""} ${params.row.lastName || ""}`,
    // },
  ];


  // *************** handlers
  const handleClickRoles = (params) => {
    setUserSelected({
      user_id: params.row.user_id,
      hierarchy_id: params.row.hierarchy_id,
      user_name: params.row.user_name,
      user_email: params.row.user_email,
      user_phone: params.row.user_phone,
      user_image_url: params.row.user_image_url,
    });

    setOpenConfirmation(true);
  };

  const handleClickRemove = (params) => {
    setUserSelected({
      user_id: params.row.user_id,
      hierarchy_id: params.row.hierarchy_id,
      user_name: params.row.user_name,
      user_email: params.row.user_email,
      user_phone: params.row.user_phone,
      user_image_url: params.row.user_image_url,
    });

    setOpenConfirmation(true);
  };

  const handleRemoving = () => {
    RemoveUser(userSelected.user_id, userSelected.hierarchy_id, userSelected)
      .then((response) => {
        setMessageGlobalModal("El usuario se elimino correctamente.");
        setOpenGlobalModal(true);
      })
      .catch((error) => {
        setMessageModal(
          `Error al intentar eliminar usuario [${error.message}].`
        );
        setOpenGlobalModal(true);
      });

    handleCloseConfirmation();
  };

  const handleClickEdit = (params) => {
    setUserSelected({
      user_id: params.row.user_id,
      hierarchy_id: params.row.hierarchy_id,
      user_name: params.row.user_name,
      user_email: params.row.user_email,
      user_phone: params.row.user_phone,
      user_image_url: params.row.user_image_url,
    });

    setInputRoleNames(splitRoles(params));

    // Load form dilog with custom data
    setInputHierarchyId(params.row.hierarchy_id);
    setInputUserName(params.row.user_name);
    setInputEmail(params.row.user_email);
    setInputPhone(params.row.user_phone);
    setInputImageUrl(params.row.user_image_url);
    setOpenDialog(true);
  };

  const handleClickUpload = () => {
    window.alert("upload file");
  };

  const handleClickOpen = () => {
    cleanFields();
    setOpenDialog(true);
  };

  const handleClose = () => {
    setUserSelected(null);
    cleanFields();
    setOpenDialog(false);
  };

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handleInputHierarchyId = (event) => {
    const value = event.target.value;
    if (value === 0 || value === null) {
      setErrorHierarchyId(true);
    } else {
      setErrorUserName(false);
      setInputUserName(value);
    }
  };

  const handleInputUserName = (event) => {
    const value = event.target.value;
    if (value.length < 1 || value.length > 50) {
      setInputUserName(value.substring(0, 50));
      // setErrorUserName(true)
    } else {
      setErrorUserName(false);
      setInputUserName(value);
    }
  };

  const handleInputUserPassword = (event) => {
    const value = event.target.value;
    if (value.length < 8 || value.length > 20) {
      setInputUserPassword(value.substring(0, 20));
      // setErrorUserName(true)
    } else {
      setErrorUserPassword(false);
      setInputUserPassword(value);
    }
  };

  const handleInputEmail = (event) => {
    const value = event.target.value;
    if (value.length < 1 || value.length > 150) {
      setInputEmail(value.substring(0, 150));
      // setErrorEmail(true)
    } else {
      setErrorEmail(false);
      setInputEmail(value);
    }
  };

  const handleInputPhone = (event) => {
    const value = event.target.value;
    if (value.length < 9 || value.length > 10) {
      setInputPhone(value.substring(0, 10));
      // setErrorPhone(true)
    } else {
      setErrorPhone(false);
      setInputPhone(value);
    }
  };

  const handleInputImageUrl = (event) => {
    const value = event.target.value;
    if (value.length < 5 || value.length > 500) {
      setInputImageUrl(value.substring(0, 500));
      // setErrorImageUrl(true)
    } else {
      setErrorImageUrl(false);
      setInputImageUrl(value);
    }
  };

  const handleCloseConfirmation = () => {
    setUserSelected(null);
    setOpenConfirmation(false);
  };
  const handleOpenConfirmation = () => {
    setOpenConfirmation(true);
  };

  const cleanFields = () => {
    setInputHierarchyId("");
    setInputUserName("");
    setInputUserPassword("");
    setInputEmail("");
    setInputPhone("");
    setInputImageUrl("");
  };

  const handleCallbackHierarchy = (newHierarchyId) => {
    setInputHierarchyId(newHierarchyId);
  };


  // // Data Load
  // const handleFileChange = (e) => {
  //   Papa.parse(event.target.files[0], {
  //     header: true,
  //     skipEmptyLines: true,
  //     complete: function (results) {
  //       const rowsArray = [];
  //       const valuesArray = [];

  //       // Iterating data to get column name and their values
  //       results.data.map((d) => {
  //         rowsArray.push(Object.keys(d));
  //         valuesArray.push(Object.values(d));
  //       });

  //       const IDENTIFICATION_COL_INDEX=0;
  //       const IDENTIFICATION_COL_NAME="CEDULA"

  //       const LASTNAME_COL_INDEX=1;
  //       const LASTNAME_COL_NAME="NOMBRES";

  //       const FIRSTNAME_COL_INDEX=2;
  //       const FIRSTNAME_COL_NAME="APELLIDOS";

  //       const PHONE_COL_INDEX=3;
  //       const PHONE_COL_NAME="CELULAR";

  //       if (rowsArray[0][IDENTIFICATION_COL_INDEX]!==IDENTIFICATION_COL_NAME
  //           && rowsArray[0][LASTNAME_COL_INDEX]!==LASTNAME_COL_NAME
  //           && rowsArray[0][FIRSTNAME_COL_INDEX]!==FIRSTNAME_COL_NAME
  //           && rowsArray[0][PHONE_COL_INDEX]!==PHONE_COL_NAME
  //         ) {
  //         setMessageGlobalModal(`El archivo no tiene el formato correcto
  //         (${IDENTIFICATION_COL_NAME}, ${LASTNAME_COL_NAME}, ${FIRSTNAME_COL_NAME}, ${PHONE_COL_NAME}).`);
  //         setOpenGlobalModal(true);
  //         return;
  //       }

  //       // Parsed Data Response in array format
  //       setParsedData(results.data);

  //       // Filtered Column Names
  //       setTableRows(rowsArray[0]);

  //       // Filtered Values
  //       setValues(valuesArray);

  //       let arrayPayload= [];
  //       let invalidIdentifications="";
  //       let invalidPhones="";

  //       valuesArray.map((item)=>{

  //           // Valido la identificacion y el telefono
  //           if (
  //             !validator.ci(item[IDENTIFICATION_COL_INDEX]) &&
  //             !validator.ruc(item[IDENTIFICATION_COL_INDEX])
  //           ) {
  //             invalidIdentifications += `${item[IDENTIFICATION_COL_INDEX]}, `;
  //           }

  //           if (
  //             !validator.cellphone(item[PHONE_COL_INDEX])
  //           ) {
  //             invalidPhones += `${item[PHONE_COL_INDEX]}, `;
  //           }

  //           const itemPayload={
  //             user_name: item[FIRSTNAME_COL_INDEX],
  //             user_email: item[LASTNAME_COL_INDEX],
  //             user_image_url: item[IDENTIFICATION_COL_INDEX],
  //             user_phone: item[PHONE_COL_INDEX]
  //           };
  //           arrayPayload.push(itemPayload);
  //         });

  //       if (invalidIdentifications.length>0) invalidIdentifications = `Cedula/RUC invalidos: ${invalidIdentifications}`
  //       if (invalidPhones.length>0) invalidPhones = `Celulares invalidos: ${invalidPhones}`
  //       if (invalidIdentifications.length>0 || invalidPhones.length>0) {
  //         setMessageGlobalModal(
  //           `${invalidIdentifications}\r\n ${invalidPhones}`
  //         );
  //         setOpenGlobalModal(true);
  //         return;
  //       }

  //       SaveManyCustomer({arrayPayload})
  //       .then((res) => {
  //         setMessageGlobalModal("El se cargo correctamente.");
  //         setOpenGlobalModal(true);

  //         //clean fields
  //         cleanFields();
  //       })
  //       .catch((error) => {
  //         setMessageGlobalModal(
  //           `Error al intentar grabar datos del archivo [${error.message}].\r\nRevise por favor.`
  //         );
  //         setOpenGlobalModal(true);
  //       });

  //     },
  //   });

  // };

  const handleCallbackRole = (newRoleIds) => {
    // console.log(`newRoleIds ${newRoleIds}`);
    setInputRoleIds(newRoleIds);
  };

  /* BACKDROP */
  const handleBackdropClick = (e) => {
    //these fail to keep the modal open
    e.stopPropagation();
    return false;
  };

  return (
    <div className="m-2 md:m-10 p-2 md:p-10 bg-white bg-opacity-80 rounded-3xl ">
      <div className="flex flex-row justify-between items-center">
        <Header category="Página" title="Usuarios" />
        <div>
          <NavButton
            title="Agregar Usuario"
            customFunc={() => handleClickOpen()}
            color={currentColor}
            icon={<MdAdd />}
          />

          {/* UPLOAD CSV */}
          {/* <input onChange={handleFileChange} ref={inputRef} type="file" id="upload" style={{display: "none"}} />
          <NavButton
            title="Cargar Usuarios"
            customFunc={() => openFileInput()}
            color={currentColor}
            icon={<MdUploadFile />}
          /> */}
        </div>
      </div>
      <div>
        <div>
          {/* DIALOG CONFIRMATION  */}
          <Dialog
            open={openConfirmation}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleCloseConfirmation}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle>{`Eliminar a ${userSelected?.user_name} (${userSelected?.user_email}) ? `}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                Desea eliminar este usuario de la plataforma ?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseConfirmation}>Cancelar</Button>
              <Button onClick={handleRemoving}>Aceptar</Button>
            </DialogActions>
          </Dialog>

          {/* *** FORM DIALOG *** */}
          <Dialog
            sx={{ "& .MuiDialog-paper": { width: "100%", maxHeight: 500 } }}
            maxWidth="xs"
            {...other}
            open={openDialog}
            onClick={(e) => e.stopPropagation()}
            disableEscapeKeyDown={true}
            onClose={handleClose}
            PaperProps={{
              component: "form",
              onSubmit: (event) => {
                event.preventDefault();
                const formData = new FormData(event.currentTarget);
                const formJson = Object.fromEntries(formData.entries());

                // Validation
                const _hierarchyId = formJson.hierarchyId;
                const _userName = formJson.userName;
                const _userPassword = formJson.userPassword;
                const _email = formJson.email;
                const _phone = formJson.phone;
                const _image_url = formJson.imageUrl;

                // // Identification validation
                // if (!validator.cellphone(_phone)) {
                //   setMessageModal(
                //     `Celular ${_phone} es invalido.\r\nRevise por favor.`
                //   );
                //   setOpenModal(true);
                //   return;
                // }

                // Enviar al ENDPOINT y espeerar respuesta antes de cerrar
                let payload;
                if (!userSelected) {

                  /*  NEW USER */

                  payload = {
                    hierarchyId: _hierarchyId,
                    userName: _userName,
                    userPassword: _userPassword,
                    userEmail: _email,
                    userPhone: _phone,
                    userImageUrl: _image_url,
                    userRoles: inputRoleIds,
                  };
                  // console.log(JSON.stringify(payload))

                  SaveUser(payload)
                    .then((res) => {
                      setMessageGlobalModal(
                        "El Usario se grabo correctamente."
                      );
                      setOpenGlobalModal(true);
                      handleClose();

                      //clean fields
                      cleanFields();
                      handleClose();
                    })
                    .catch((error) => {
                      setMessageModal(
                        `Error al intentar grabar usuario [${error.message}].\r\nRevise por favor.`
                      );
                      setOpenModal(true);
                    });
                } else {

                  /* UPDATE USER */

                  payload = {
                    userId: userSelected.user_id,
                    hierarchyId: userSelected.hierarchy_id,
                    newHierarchyId: _hierarchyId,
                    userName: _userName,
                    userEmail: _email,
                    userPhone: _phone,
                    userImageUrl: _image_url,
                    userRoles: inputRoleIds,
                  };
                  // console.log(JSON.stringify(payload));

                  UpdateUser(payload)
                    .then((res) => {
                      setMessageGlobalModal(
                        "El Usuario se actualizo correctamente."
                      );
                      setOpenGlobalModal(true);
                      handleClose();

                      //clean fields
                      cleanFields();
                      handleClose();
                    })
                    .catch((error) => {
                      setMessageGlobalModal(
                        `Error al intentar actualizar usuario [${error.message}].\r\nRevise por favor.`
                      );
                      setOpenGlobalModal(true);
                    });
                }
                resetTable();
              },
            }}
          >
            <DialogTitle>
              {!userSelected ? "AGREGAR USUARIO" : "MODIFICAR USUARIO"}
            </DialogTitle>
            <DialogContent>
              <div>
                {/* MODAL */}
                <Modal
                  keepMounted
                  open={openModal}
                  onClose={handleCloseModal}
                  aria-labelledby="keep-mounted-modal-title"
                  aria-describedby="keep-mounted-modal-description"
                >
                  <Box sx={style}>
                    <Typography
                      id="keep-mounted-modal-title"
                      variant="h6"
                      component="h2"
                    >
                      {messageModal}
                    </Typography>
                  </Box>
                </Modal>
              </div>
              {/* <DialogContentText>
                NUEVO USUARIO
              </DialogContentText> */}

              <HierarchySelect hierarchyId={inputHierarchyId} parentCallback={handleCallbackHierarchy} />

              <RoleSelect inputRoleNames={inputRoleNames} parentCallback={handleCallbackRole} />

              <TextField
                autoFocus
                required
                margin="dense"
                id="hierarchyId"
                name="hierarchyId"
                label="Hierarchy"
                type="number"
                fullWidth
                variant="standard"
                value={inputHierarchyId}
                // onChangeCapture={handleInputHierarchyId}
                error={errorHierarchyId}
                helperText="Debe ingresar una jerarquia valida."
              />

              <TextField
                autoFocus
                required
                margin="dense"
                id="userName"
                name="userName"
                label="Usuario"
                type="text"
                fullWidth
                variant="standard"
                value={inputUserName}
                onChangeCapture={handleInputUserName}
                error={errorUserName}
                helperText="Debe ingresar un nombre de usuario valido."
              />

              {!userSelected &&
              (
                <TextField
                autoFocus
                required
                margin="dense"
                id="userPassword"
                name="userPassword"
                label="Contraseña"
                type="password"
                fullWidth
                variant="standard"
                value={inputUserPassword}
                onChangeCapture={handleInputUserPassword}
                error={errorUserPassword}
                helperText="Debe ingresar una contraseña valido."
              />

              )
              }

              <TextField
                required
                margin="dense"
                id="email"
                name="email"
                label="Correo"
                type="email"
                fullWidth
                variant="standard"
                value={inputEmail}
                onChangeCapture={handleInputEmail}
                error={errorEmail}
                helperText="Debe ingresar un correo valido."
              />

              <TextField
                required
                margin="dense"
                id="phone"
                name="phone"
                label="Celular"
                type="number"
                fullWidth
                variant="standard"
                value={inputPhone}
                onChangeCapture={handleInputPhone}
                error={errorPhone}
                helperText="Debe ingresar un celular valido."
              />
              
              <div className=" flex items-start justify-between">
                <div className=" w-5/6">
                  <TextField
                    // required
                    margin="dense"
                    id="imageUrl"
                    name="imageUrl"
                    label="Imagen"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={inputImageUrl}
                    onChangeCapture={handleInputImageUrl}
                    error={errorImageUrl}
                    helperText="Debe ingresar una imagen valida."
                  />
                </div>
                <div>
                  <input
                    onChange={handleOnChangeImageFile}
                    ref={inputImageRef}
                    type="file"
                    id="upload"
                    style={{ display: "none" }}
                  />
                  <NavButton
                    title="Cargar Imagen"
                    customFunc={() => openFileImageInput()}
                    color={currentColor}
                    icon={<MdUploadFile />}
                  />
                </div>

              </div>
            </DialogContent>
            <DialogActions>
              <Button variant="outlined" color="error" onClick={handleClose}>
                Cancelar
              </Button>
              <Button type="submit" variant="contained" endIcon={<SendIcon />}>
                Grabar
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        {data && (
          <Box sx={{ width: "100%" }}>
            <DataGrid
              key={seed}
              rows={data}
              columns={columns}
              getRowId={(row) => row?.user_id}
              columnVisibilityModel={columnVisibilityModel}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 5 },
                },
              }}
              pageSizeOptions={[5, 10]}
              // checkboxSelection
            />
          </Box>
        )}
      </div>
    </div>
  );
}

export default Users;
