import React, {useState, useEffect} from 'react'
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

import Header from "../components/Header";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

// Data
import { ResetAction } from "../api/auth";
import { useStateContext } from "../contexts/ContextProvider";

const Forgot = (props) => {
    const params = useParams();
    const token = params.token;
    const [open, setOpen] = React.useState(true);
    const [password1, setPassword1] = useState("");
    const [password2, setPassword2] = useState("");
    const [enableSend, setEnableSend] = useState(false);
    const navigate = useNavigate();
    const { setOpenGlobalModal, setMessageGlobalModal } = useStateContext();


    useEffect(() => {
        if (password1.length>0 && password2.length>0) {
            if (password1===password2) {setEnableSend(true)} else {setEnableSend(false)};
        }
    
      return () => {
        
      }
    }, [password1, password2])
    



    const handleClose = () => {
        setOpen(false);
        navigate("/", {replace: true});
      };

      const handlePassword1 = (e) => {
        setPassword1(e.target.value);
        
      };

      const handlePassword2 = (e) => {
        setPassword2(e.target.value);

        
      };



  return (
    <div className="m-2 md:m-10 p-2 md:p-10 bg-white bg-opacity-80 rounded-3xl">
        <div className="flex flex-col justify-between">
            <Header category="Página" title="Recuperación de Clave" />
            <div className=' h-screen'>

                <React.Fragment>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                    component: 'form',
                    onSubmit: async (event) => {
                        event.preventDefault();
                        const formData = new FormData(event.currentTarget);
                        const formJson = Object.fromEntries(formData.entries());
                        const _password1 = formJson.password1;
                        const _password2 = formJson.password2;

                        const payload={
                            token: token,
                            password: _password2
                          };

                          
                        await ResetAction(payload)
                        .then((res) => {
                          if (res) {
                            setMessageGlobalModal(`La clave se cambio satisfactoriamente.`)
                            setOpenGlobalModal(true);
                          } else {
                            setMessageGlobalModal("El token es invalido.")
                            setOpenGlobalModal(true);        
                          }
                    
                        })
                        .catch((error) => {
                          setMessageGlobalModal(`Error al intentar cambiar la clave [${error.message}].`);
                          setOpenGlobalModal(true);
                        });


                        handleClose();
                        
                    },
                    }}
                >
                    <DialogTitle>Reset Password</DialogTitle>
                    <DialogContent>
                    <DialogContentText>
                        Registre su nueva clave por favor.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        id="password1"
                        name="password1"
                        label="Nueva clave"
                        type="password"
                        fullWidth
                        variant="standard"
                        value={password1}
                        onChange={handlePassword1}
                    />
                    <TextField
                        required
                        margin="dense"
                        id="password2"
                        name="password2"
                        label="Confirme clave"
                        type="password"
                        fullWidth
                        variant="standard"
                        value={password2}
                        onChange={handlePassword2}
                    />
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={handleClose}>Cancelar</Button>
                    {enableSend &&
                        <Button type="submit">Cambiar Clave</Button>
                    }
                    </DialogActions>
                </Dialog>
                </React.Fragment>

            </div>
        </div>
    </div>
  )
}

export default Forgot