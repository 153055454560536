import useSWR, { preload, useSWRConfig } from "swr";
import {
  fetcher,
  fetcherUnAuth,
  fetcherPayload,
  poster,
  updater,
  remover,
  swrOptions,
} from "./index";

const API_ENDPOINT = "/role";

export const PreGetRoles = (props) => {
  try {
    return preload(`${API_ENDPOINT}/all`, fetcher);
  } catch (error) {
    throw new Error(error);
  }
};

export const GetRoles = (props) => {
  try {
    return useSWR(`${API_ENDPOINT}/all`, fetcher, swrOptions);
  } catch (error) {
    throw new Error(error);
  }
};

export const SaveRole = (payload) => {
  try {
    return poster(API_ENDPOINT, payload);
  } catch (error) {
    throw new Error(error);
  }
};

export const SaveManyRoles = (payload) => {
  try {
    return poster(`${API_ENDPOINT}/many`, payload);
  } catch (error) {
    throw new Error(error);
  }
};

export const UpdateRole = (payload) => {
  try {
    return updater(`${API_ENDPOINT}`, payload);
  } catch (error) {
    throw new Error(error);
  }
};

export const RemoveRole = (userId, hierarchyId, payload) => {
  try {
    return remover(`${API_ENDPOINT}/${userId}/${hierarchyId}`);
  } catch (error) {
    throw new Error(error);
  }
};

export default {
  PreGetRoles,
  GetRoles,
  SaveRole,
  RemoveRole,
  UpdateRole,
  SaveManyRoles,
};
