import useSWR, { preload, useSWRConfig  } from 'swr';
import { fetcher, poster, posterLogin, updater, remover } from './index';

const API_ENDPOINT='/auth';

const swrOptions ={
    revalidateOnReconnect: true,
    revalidateOnFocus: true,
    dedupingInterval: 3000,          // you can only refetched after x milisecons
    refreshInterval: 1500,             // refetch data every x miliseconds
};


export const LoginAction = async (payload) => {
    try {
      return await posterLogin(`${API_ENDPOINT}/login`, payload);
    } catch (error) {
      throw new Error(error);
    }
}

export const ForgotAction = async (payload) => {
  try {
    return await posterLogin(`${API_ENDPOINT}/forgotPassword`, payload);
  } catch (error) {
    throw new Error(error);
  }
}

export const ResetAction = async (payload) => {
  try {
    return await posterLogin(`${API_ENDPOINT}/resetPassword`, payload);
  } catch (error) {
    throw new Error(error);
  }
}


export default {LoginAction, ForgotAction, ResetAction}