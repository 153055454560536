import React, { createContext, useContext, useState, useEffect, useMemo } from "react";
import axios from 'axios';
const StateContext = createContext();

const initialState = {
  chat: false,
  cart: false,
  userProfile: false,
  notification: false,
};

export const ContextProvider = ({ children }) => {

  // Get Mode
  const initialMode = () => {
    return localStorage.getItem("themeMode") || "Dark";
  };
  // Get Color
  const initialColor = () => {
    return localStorage.getItem("colorMode") || "#03C9D7";
  };

  const [screenSize, setScreenSize] = useState(900);
  const [currentColor, setCurrentColor] = useState(initialColor);
  const [currentMode, setCurrentMode] = useState(initialMode);
  const [themeSettings, setThemeSettings] = useState(false);
  const [activeMenu, setActiveMenu] = useState(true);
  const [isClicked, setIsClicked] = useState(initialState);

  const [messageGlobalModal, setMessageGlobalModal] = useState("");
  const [openGlobalModal, setOpenGlobalModal] = useState(false);
  const [messageGlobalModalError, setMessageGlobalModalError] = useState("");
  const [openGlobalModalError, setOpenGlobalModalError] = useState(false);

  const [token, setToken_] = useState(sessionStorage.getItem('token'));

  const [userLogged, setUserLogged_] = useState(sessionStorage.getItem('userLogged'));

  const setMode = (e) => {
    setCurrentMode(e.target.value);
    localStorage.setItem("themeMode", e.target.value);
    setThemeSettings(false);
  };

  const setColor = (color) => {
    setCurrentColor(color);
    localStorage.setItem("colorMode", color);
    setThemeSettings(false);
  };

  // Function to set the authentication token
  const setToken = (newToken) => {
    // console.log(`guardando token> ${newToken} en session`)
    sessionStorage.setItem('token', newToken);
    setToken_(newToken);
  };

  const setUserLogged = (userLogged) => {
    // console.log(`storing ${JSON.stringify(userLogged)}`);
    sessionStorage.setItem('userLogged', JSON.stringify(userLogged));
    setUserLogged_(JSON.stringify(userLogged));
  };

  // Memoized value of the authentication context
  const contextValue = useMemo(
    () => ({
      token,
      setToken,
    }),
    [token]
  );

  const handleClick = (clicked) =>
    setIsClicked({ ...initialState, [clicked]: true });

  useEffect(() => {
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
      sessionStorage.setItem('token', token);
      sessionStorage.setItem('userLogged', userLogged);
    } else {
      delete axios.defaults.headers.common["Authorization"];
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('userLogged');
    }
  }, [token]);

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <StateContext.Provider
      value={{
        currentColor,
        currentMode,
        activeMenu,
        screenSize,
        setScreenSize,
        handleClick,
        isClicked,
        initialState,
        setIsClicked,
        setActiveMenu,
        setCurrentColor,
        setCurrentMode,
        setMode,
        setColor,
        themeSettings,
        setThemeSettings,
        openGlobalModal,
        setOpenGlobalModal,
        messageGlobalModal,
        setMessageGlobalModal,
        openGlobalModalError,
        setOpenGlobalModalError,
        messageGlobalModalError,
        setMessageGlobalModalError,
        token,
        setToken,
        userLogged, setUserLogged,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = () => useContext(StateContext);
