import React, { useEffect, useState } from "react";
import useSWR from "swr";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import CancelIcon from "@mui/icons-material/Cancel";

import { api, swrOptions, fetcher } from "../api";

const API_ENDPOINT = "/role";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, roleName, theme) {
  return {
    fontWeight:
    roleName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const RoleSelect = (props) => {
  const {
    data: roles,
    isLoading,
    isValidating,
    error,
  } = useSWR(`${API_ENDPOINT}/all`, fetcher, swrOptions);
  // console.log(JSON.stringify(roles));
  
  const [roleName, setRoleName] = useState([]);
  const [roleId, setRoleId] = useState([]);

  useEffect(() => {
    // console.log(`props.inputRoleNames ${props.inputRoleNames}`);
    if (props.inputRoleNames) setRoleName(props.inputRoleNames);
    
    return () => {
    }
  }, [])
  
  useEffect(() => {
    // console.log(`roleName ${roleName}`);
    if (Array.isArray(roleName) && roleName!==undefined && roleName.length>0 ) {
      let _ids=new Array;

      roleName?.forEach(name => {
        _ids.push({
          role_id: roles?.find(p=>p.role_name===name)?.role_id,
          role_name: roles?.find(p=>p.role_name===name)?.role_name,
        })
      });

      setRoleId(_ids);
      props.parentCallback(_ids);
    }
    return () => {
    }
  }, [roleName])

  const theme = useTheme();
  
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setRoleName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    event.preventDefault();    
  };
  
  return (
    <FormControl sx={{ m: 1, width: 300 }}>
      <InputLabel id="multiple-chip-label">Roles</InputLabel>
      <Select
        labelId="multiple-chip-label"
        id="multiple-chip"
        multiple
        value={roleName}
        onChange={handleChange}
        input={<OutlinedInput id="select-multiple-chip" label="Roles" />}
        renderValue={(selected) => {
          return (
            <Stack gap={1} direction="row" flexWrap="wrap">
            {selected.map((value) => (
              <Chip
                key={value}
                label={value}
                onDelete={() =>
                  setRoleName(
                    roleName.filter((item) => item !== value)
                  )
                }
                deleteIcon={
                  <CancelIcon
                    onMouseDown={(event) => event.stopPropagation()}
                  />
                }
              />
            ))}
          </Stack>
          )
        }}
        MenuProps={MenuProps}
      >
        {roles && 
          roles?.map((role) => (
          <MenuItem
            key={role?.role_id}
            value={role?.role_name}
            style={getStyles(name, roleName, theme)}
          >
            {role?.role_name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default RoleSelect;
