import useSWR, { preload, useSWRConfig } from "swr";
import {
  fetcher,
  fetcherUnAuth,
  fetcherPayload,
  poster,
  updater,
  remover,
  swrOptions,
} from "./index";

const API_ENDPOINT = "/lottery";

// const swrOptions = {
//   revalidateOnReconnect: true,
//   revalidateOnFocus: true,
//   dedupingInterval: 3000, // you can only refetched after x milisecons
//   refreshInterval: 1500, // refetch data every x miliseconds
// };

export const PreGetLotteries = (props) => {
  try {
    return preload(`${API_ENDPOINT}/all`, fetcher);
  } catch (error) {
    throw new Error(error);
  }
};

export const GetLotteries = (props) => {
  try {
    return useSWR(`${API_ENDPOINT}/all`, fetcher, swrOptions);
  } catch (error) {
    throw new Error(error);
  }
};

export const SaveLottery = (payload) => {
  try {
    return poster(API_ENDPOINT, payload);
  } catch (error) {
    throw new Error(error);
  }
};

export const SaveManyLottery = (payload) => {
  try {
    return poster(`${API_ENDPOINT}/many`, payload);
  } catch (error) {
    throw new Error(error);
  }
};

export const UpdateLottery = (payload) => {
  try {
    return updater(`${API_ENDPOINT}`, payload);
  } catch (error) {
    throw new Error(error);
  }
};

export const RemoveLottery = (id, payload) => {
  try {
    return remover(`${API_ENDPOINT}/${id}`);
  } catch (error) {
    throw new Error(error);
  }
};

export default {
  PreGetLotteries,
  GetLotteries,
  SaveLottery,
  RemoveLottery,
  UpdateLottery,
  SaveManyLottery,
};
