import React, { useState, useEffect } from "react";
import Tickets from './Tickets';
import validator from "ecuador-validator";

// Contexts
import { useStateContext } from "./contexts/ContextProvider";


// Data
import { fetcherUnAuth, swrOptions } from "./api/index";

import "./HomePage.css";

const API_ENDPOINT = "/customer";


const HomePage = (props) => {
  console.log(`ENVIRONMENT: ${process.env.REACT_APP_ENVIRONMENT}`);
  
  const [showTickets, setShowTickets] = useState(false);

  const [inputIdentification, setInputIdentification] = useState("");
  const [identification, setIdentification] = useState(null);

  // useEffect(() => {
  //   setIdentification(inputIdentification);
  //   return () => {};
  // }, [inputIdentification]);

  // Global Context
  const {
    setOpenGlobalModal,
    setMessageGlobalModal,
    setOpenGlobalModalError,
    setMessageGlobalModalError,
  } = useStateContext();

  const handleInputIdentification = (e) => {
    setInputIdentification(e.target.value);
  };

  const handleClickSearchButton = async () => {

    if(!validator.ci(inputIdentification) && !validator.ruc(inputIdentification)) {
      setMessageGlobalModal("El numero de identificación es invalido.");
      setOpenGlobalModal(true);
      return;
    }
    setShowTickets(true);
  };

  const handleCallbackModal = (stateModal) => {
    setShowTickets(stateModal)
  };


  return (
    <div>
      {showTickets &&
        <Tickets identification={inputIdentification} parentCallback={handleCallbackModal} />
      }
      <div className="banner-container">
        <div className="text-center w-2/4">
          {/* <h1 className=" text-6xl text-orange-600 font-bold">
            Bienvenido a su Cooperativa de Ahorro y Credito
          </h1> */}
          <h4 className="text-4xl mt-8 text-white m-5">
            <span className=" mr-10">Consulte sus boletos aqui</span>
          </h4>
          <div className=" flex-row bg-white text-right">
            <div className="text-xl">
              <input
                id="identification"
                name="identification"
                onBlurCapture={handleInputIdentification}
                type="number"
                className="border-0 text-center p-5"
                placeholder="Cedula/RUC"
              />
              <button
                id="btnSearchOperations"
                name="btnSearchOperations"
                className="bg-slate-500 text-white p-5"
                onClick={handleClickSearchButton}
              >
                Consultar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
