import React, {useEffect} from 'react';
import { Navigate, Outlet, useLocation   } from "react-router-dom";
// import { useAuth } from "../contexts/AuthProvider";
import {useStateContext} from '../contexts/ContextProvider';

export const ProtectedRoute = () => {

  const { token } = useStateContext();
  const auth = null; 
  const location = useLocation()
  // console.log(JSON.stringify(location.pathname));



  const user = JSON.parse(sessionStorage.getItem("userLogged"));
  // console.log(user.user_permissions.filter(permission=>permission.url===location.pathname));
  if (location.pathname==="/") return <Outlet />;
  if (token && user.user_permissions.filter(permission=>permission.url===location.pathname).length>0 ) {
    return <Outlet />;
  } else if (!token) {
    return <Navigate to="/login" />;
  } else {
    return <Navigate to="/unauthorized" />;
  }

};

export default ProtectedRoute;