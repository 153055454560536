import React, { useEffect, useState } from "react";
import { BsCurrencyDollar } from "react-icons/bs";
// import { GoPrimitiveDot } from 'react-icons/go';
import { IoIosMore } from "react-icons/io";

import { MdOutlineSupervisorAccount } from "react-icons/md";
import { IoTicketOutline } from "react-icons/io5";
import { GrDocumentPerformance } from "react-icons/gr";
import { CiBank } from "react-icons/ci";
import { GrMoney } from "react-icons/gr";

import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { Stacked, Pie, Button, LineChart, SparkLine } from "../components";

import { BarChart } from "@mui/x-charts/BarChart";
import { PieChart, pieArcLabelClasses } from "@mui/x-charts/PieChart";
import { axisClasses } from "@mui/x-charts/ChartsAxis";
import { legendClasses } from "@mui/x-charts/ChartsLegend";

import {
  earningData,
  medicalproBranding,
  recentTransactions,
  weeklyStats,
  dropdownData,
  SparklineAreaData,
  ecomPieChartData,
} from "../data/dummy";
import { useStateContext } from "../contexts/ContextProvider";
import product9 from "../data/product9.jpg";
import { formatCurrency } from "../utils/formatter";
import { FilterData } from "../components";

// Data
import {
  GetAllEarnings,
  GetAllEarningsDay,
  GetAllEarningsHierarchy,
  GetAllEarningsUser,
} from "../api/dashboard";
import { GiConsoleController } from "react-icons/gi";

const DropDown = ({ currentMode }) => (
  <div className="w-28 border-1 border-color px-2 py-1 rounded-md">
    <DropDownListComponent
      id="time"
      fields={{ text: "Time", value: "Id" }}
      style={{ border: "none", color: currentMode === "Dark" && "white" }}
      value="1"
      dataSource={dropdownData}
      popupHeight="220px"
      popupWidth="120px"
    />
  </div>
);

export function Ecommerce(props) {
  const [filter, setFilter] = useState(null);
  const [seedFilter, setSeedFilter] = useState(null);
  const [seedFilter2, setSeedFilter2] = useState(null);
  const [dataEarnings, setDataEarnings] = useState(null);
  const [dataEarningsDay, setDataEarningsDay] = useState(null);
  const [dataEarningsHierarchy, setDataEarningsHierarchy] = useState(null);
  const [dataEarningsUser, setDataEarningsUser] = useState(null);
  const [sparkLineAreaDataDay, setSparkLineAreaDataDay] = useState(null);
  const [sparkLineAreaDataHierarchy, setSparkLineAreaDataHierarchy] =
    useState(null);
  const [sparkLineAreaDataUser, setSparkLineAreaDataUser] = useState(null);
  const [pieChartDataHierarchy, setPieChartDataHierarchy] = useState(null);
  const [pieChartDataUser, setPieChartDataUser] = useState(null);

  const { currentColor, currentMode } = useStateContext();

  useEffect(() => {
    let arr = new Array();
    (async () => {
      // total
      const earnings = await GetAllEarnings(filter);
      await setDataEarnings(earnings);
      // console.log(`earnings: ${console.log(JSON.stringify(earnings))}`);

      // day
      arr = [];
      const earningsDay = await GetAllEarningsDay(filter);
      await setDataEarningsDay(earningsDay);
      earningsDay?.forEach(async (element) => {
        // arr.push({
        //   x: element?.day,
        //   yval: element._sum?.customer_operation_amount,
        // });
        arr.push({
          amount: Number(element._sum?.customer_operation_amount),
          day: `${element?.day}`,
        });
      });
      await setSparkLineAreaDataDay(arr);

      // hierarchy
      arr = [];
      const earningsHierarchy = await GetAllEarningsHierarchy(filter);
      await setDataEarningsHierarchy(earningsHierarchy);
      earningsHierarchy?.forEach((element, index) => {
        // arr.push({
        //   x: index,
        //   xval: `${element?.hierarchy_name}`,
        //   yval: element._sum?.customer_operation_amount,
        // });
        arr.push({
          amount: Number(element._sum?.customer_operation_amount),
          hierarchy: `${element?.hierarchy_name}`,
        });
      });
      await setSparkLineAreaDataHierarchy(arr);

      // User
      arr = [];
      const earningsUser = await GetAllEarningsUser(filter);
      await setDataEarningsUser(earningsUser);
      earningsUser?.forEach((element, index) => {
        // arr.push({
        //   x: index,
        //   xval: `${element?.user_name}`,
        //   yval: element._sum?.customer_operation_amount,
        // });
        arr.push({
          amount: Number(element._sum?.customer_operation_amount),
          user: `${element?.user_name}`,
        });
      });
      await setSparkLineAreaDataUser(arr);

      // pie hierarchy
      arr = [];
      earningsHierarchy?.forEach((element, index) => {
        let perc = Math.round(
          (Number(element._sum?.customer_operation_amount) /
            Number(earnings?.amount)) *
            100
        );
        // arr.push({
        //   x: `${element?.hierarchy_name}`,
        //   y: perc,
        //   yval: element._sum?.customer_operation_amount,
        //   text: `${perc}%`,
        // });
        arr.push({
          id: index,
          value: perc,
          label: `${element?.hierarchy_name} : $${element._sum?.customer_operation_amount} (${perc}%)`,
        });
      });
      await setPieChartDataHierarchy(arr);
      // console.log(JSON.stringify(pieChartDataHierarchy));

      // pie user
      arr = [];
      earningsUser?.forEach((element, index) => {
        let perc = Math.round(
          (Number(element._sum?.customer_operation_amount) /
            Number(earnings?.amount)) *
            100
        );
        // arr.push({
        //   x: `${element?.hierarchy_name}`,
        //   y: perc,
        //   yval: element._sum?.customer_operation_amount,
        //   text: `${perc}%`,
        // });
        arr.push({
          id: index,
          value: perc,
          label: `${element?.user_name} : $${element._sum?.customer_operation_amount} (${perc}%)`,
        });
      });
      await setPieChartDataUser(arr);
      // console.log(JSON.stringify(pieChartDataUser));
    })();
  }, [seedFilter]);

  const currentFontColor = currentMode == "Dark" ? "#776e6e" : "#000000";
  // Chart1
  const valueFormatter = (value) => `$${value}`;

  const chartSettingNumber = {
    yAxis: [
      {
        label: "Monto operación (USD$)",
      },
    ],
    grid: { horizontal: true },
    series: [
      {
        dataKey: "amount",
        label: "Monto",
        valueFormatter,
      },
    ],
    slotProps: {
      legend: {
        labelStyle: {
          fontSize: 14,
          fill: currentFontColor,
        },
      },
    },
    // colors: [currentFontColor], //color for each serie
    height: 250,
    width: 700,
    sx: {
      [`& .${axisClasses.directionY} .${axisClasses.label}`]: {
        transform: "translateX(-10px)",
        strokeWidth: "0.5",
        fill: currentFontColor,
      },
      [`& .${axisClasses.bottom} .${axisClasses.tickLabel}`]: {
        strokeWidth: "0.5",
        fill: currentFontColor,
      },
      [`& .${axisClasses.bottom} .${axisClasses.line}`]: {
        strokeWidth: "0.5",
        stroke: currentFontColor,
      },
      [`& .${axisClasses.left} .${axisClasses.tickLabel}`]: {
        strokeWidth: "0.5",
        fill: currentFontColor,
      },
      [`& .${axisClasses.left} .${axisClasses.line}`]: {
        strokeWidth: "0.5",
        stroke: currentFontColor,
      },
    },
  };

  const chartSettingCategory = {
    yAxis: [
      {
        label: "Monto operación (USD$)",
      },
    ],
    grid: { horizontal: true },
    series: [
      {
        dataKey: "amount",
        label: "Monto",
        valueFormatter,
      },
    ],
    slotProps: {
      legend: {
        labelStyle: {
          fontSize: 14,
          fill: currentFontColor,
        },
      },
    },
    // colors: [currentFontColor], //color for each serie
    height: 400,
    width: 700,
    margin: { bottom: 150 },
    sx: {
      [`& .${axisClasses.directionY} .${axisClasses.label}`]: {
        transform: "translateX(-10px)",
        strokeWidth: "0.5",
        fill: currentFontColor,
      },
      [`& .${axisClasses.bottom} .${axisClasses.tickLabel}`]: {
        strokeWidth: "0.5",
        fill: currentFontColor,
      },
      [`.${axisClasses.bottom} .${axisClasses.tickLabel}`]: {
        transform: "rotateZ(-270deg) translateX(60px) translateY(-15px)",
        fontSize: 8,
      },

      [`& .${axisClasses.bottom} .${axisClasses.line}`]: {
        strokeWidth: "0.5",
        stroke: currentFontColor,
      },
      [`& .${axisClasses.left} .${axisClasses.tickLabel}`]: {
        strokeWidth: "0.5",
        fill: currentFontColor,
      },
      [`& .${axisClasses.left} .${axisClasses.line}`]: {
        strokeWidth: "0.5",
        stroke: currentFontColor,
      },
    },
  };
  // Pie
  const pieSize = {
    width: 400,
    height: 200,
  };

  const handleCallbackFilter = async (filterParams) => {
    if (!filterParams.lotteryId) return;
    await setFilter(filterParams);
    await setSeedFilter(Math.random());
    await setSeedFilter2(Math.random());
  };

  return (
    <div>
      <FilterData parentCallback={handleCallbackFilter} />

      {filter && (
        <div>
          <div className="flex flex-wrap lg:flex-nowrap justify-center ">
            {/* AMOUNT */}
            <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg h-44 rounded-xl w-full lg:w-80 p-8 pt-9 m-3 bg-hero-pattern bg-no-repeat bg-cover bg-center">
              <div className="flex justify-between items-center">
                <div>
                  <p className="font-bold text-gray-400 text-2xl">
                    Monto Recaudado: {formatCurrency(dataEarnings?.amount)}
                  </p>
                </div>
                <button
                  type="button"
                  style={{ backgroundColor: currentColor }}
                  className="text-2xl opacity-0.9 text-white hover:drop-shadow-xl rounded-full  p-4"
                >
                  <BsCurrencyDollar />
                </button>
              </div>
            </div>
            {/* SOME DATA */}
            <div className="flex m-3 flex-wrap justify-center gap-1 items-center">
              <div
                key="customer"
                className="bg-white h-44 dark:text-gray-200 dark:bg-secondary-dark-bg md:w-56  p-4 pt-9 rounded-2xl "
              >
                <button
                  type="button"
                  style={{
                    color: "#03C9D7",
                    backgroundColor: "#E5FAFB",
                  }}
                  className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl"
                >
                  <MdOutlineSupervisorAccount />
                </button>
                <p className="mt-3">
                  <span className="text-lg font-semibold">
                    {dataEarnings?.customers}
                  </span>
                  {/* <span className={`text-sm text-${item.pcColor} ml-2`}>
                    {item.percentage}
                  </span> */}
                </p>
                <p className="text-sm text-gray-400  mt-1">
                  Socios Participantes
                </p>
              </div>

              <div
                key="tickets"
                className="bg-white h-44 dark:text-gray-200 dark:bg-secondary-dark-bg md:w-56  p-4 pt-9 rounded-2xl "
              >
                <button
                  type="button"
                  style={{
                    color: "#03C9D7",
                    backgroundColor: "#E5FAFB",
                  }}
                  className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl"
                >
                  <IoTicketOutline />
                </button>
                <p className="mt-3">
                  <span className="text-lg font-semibold">
                    {dataEarnings?.tickets}
                  </span>
                  {/* <span className={`text-sm text-${item.pcColor} ml-2`}>
                    {item.percentage}
                  </span> */}
                </p>
                <p className="text-sm text-gray-400  mt-1">Boletos emitidos</p>
              </div>

              <div
                key="operations"
                className="bg-white h-44 dark:text-gray-200 dark:bg-secondary-dark-bg md:w-56  p-4 pt-9 rounded-2xl "
              >
                <button
                  type="button"
                  style={{
                    color: "#03C9D7",
                    backgroundColor: "#E5FAFB",
                  }}
                  className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl"
                >
                  <GrDocumentPerformance />
                </button>
                <p className="mt-3">
                  <span className="text-lg font-semibold">
                    {dataEarnings?.quantity}
                  </span>
                  {/* <span className={`text-sm text-${item.pcColor} ml-2`}>
                    {item.percentage}
                  </span> */}
                </p>
                <p className="text-sm text-gray-400  mt-1">Operaciones</p>
              </div>

              <div
                key="hierarchies"
                className="bg-white h-44 dark:text-gray-200 dark:bg-secondary-dark-bg md:w-56  p-4 pt-9 rounded-2xl "
              >
                <button
                  type="button"
                  style={{
                    color: "#03C9D7",
                    backgroundColor: "#E5FAFB",
                  }}
                  className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl"
                >
                  <CiBank />
                </button>
                <p className="mt-3">
                  <span className="text-lg font-semibold">
                    {dataEarnings?.hierarchies}
                  </span>
                  {/* <span className={`text-sm text-${item.pcColor} ml-2`}>
                    {item.percentage}
                  </span> */}
                </p>
                <p className="text-sm text-gray-400  mt-1">Agencias</p>
              </div>
            </div>
            {/* DEPOSITS */}
            <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg h-44 rounded-xl w-full lg:w-80 p-8 pt-9 m-3 bg-hero-pattern bg-no-repeat bg-cover bg-center">
              <div className="flex justify-between items-center">
                <div>
                  <p className="font-bold text-gray-400 text-2xl">
                    OPERACIONES
                  </p>
                  <p className="font-bold text-gray-400 text-lg">
                    Promedio: {formatCurrency(dataEarnings?.average)}
                  </p>
                  <p className="font-bold text-gray-400 text-lg">
                    Minimo: {formatCurrency(dataEarnings?.minimun)}
                  </p>
                  <p className="font-bold text-gray-400 text-lg">
                    Maximo: {formatCurrency(dataEarnings?.maximun)}
                  </p>
                </div>
                <button
                  type="button"
                  style={{ backgroundColor: currentColor }}
                  className="text-4xl opacity-0.9 text-white hover:drop-shadow-xl rounded-full  p-4"
                >
                  <GrMoney />
                </button>
              </div>
            </div>
          </div>

          <div className="flex gap-10 flex-wrap justify-center">
            {/* BARS */}
            <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg m-3 p-4 rounded-2xl md:w-780  ">
              <div className="mt-10 flex gap-10 flex-wrap justify-center">
                <div className=" border-r-1 border-color m-4 pr-10">
                  <p className="font-semibold text-xl">Montos por Día</p>
                  {sparkLineAreaDataDay?.length > 0 && (
                    <BarChart
                      key={seedFilter + 1}
                      colors={[`${currentColor}`]}
                      dataset={sparkLineAreaDataDay}
                      xAxis={[
                        {
                          scaleType: "band",
                          dataKey: "day",
                          tickPlacement: "middle",
                          tickLabelPlacement: "middle",
                        },
                      ]}
                      {...chartSettingNumber}
                    />
                  )}
                  <p className="font-semibold text-xl">Montos por Agencia</p>
                  {sparkLineAreaDataHierarchy?.length > 0 && (
                    <BarChart
                      key={seedFilter + 2}
                      colors={[`${currentColor}`]}
                      dataset={sparkLineAreaDataHierarchy}
                      xAxis={[
                        {
                          scaleType: "band",
                          dataKey: "hierarchy",
                          tickPlacement: "middle",
                          tickLabelPlacement: "middle",
                        },
                      ]}
                      {...chartSettingCategory}
                    />
                  )}
                  <p className="font-semibold text-xl">Montos por Cajero</p>
                  {sparkLineAreaDataUser?.length > 0 && (
                    <BarChart
                      key={seedFilter + 3}
                      colors={[`${currentColor}`]}
                      dataset={sparkLineAreaDataUser}
                      xAxis={[
                        {
                          scaleType: "band",
                          dataKey: "user",
                          tickPlacement: "middle",
                          tickLabelPlacement: "middle",
                        },
                      ]}
                      {...chartSettingCategory}
                    />
                  )}
                </div>
              </div>
            </div>
            {/* PIES */}
            <div>
              <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg m-3 p-4 rounded-2xl md:w-780  ">
                <div className=" w-700">
                  <p className="text-xl font-semibold ">
                    {formatCurrency(dataEarnings?.amount)}
                  </p>
                  <p className="text-gray-400">Monto periodo</p>
                  {pieChartDataHierarchy?.length > 0 && (
                    <PieChart
                      series={[
                        {
                          data: pieChartDataHierarchy,
                          highlightScope: {
                            faded: "global",
                            highlighted: "item",
                          },
                          faded: {
                            innerRadius: 30,
                            additionalRadius: -30,
                            color: "gray",
                          },
                        },
                      ]}
                      height={500}
                      width={700}
                      margin={{ right: 300 }}
                      slotProps={{
                        legend: {
                          labelStyle: {
                            fontSize: 12,
                            fill: currentFontColor,
                          },
                        },
                      }}
                    />
                  )}
                  {pieChartDataUser?.length > 0 && (
                    <PieChart
                      series={[
                        {
                          data: pieChartDataUser,
                          highlightScope: {
                            faded: "global",
                            highlighted: "item",
                          },
                          faded: {
                            innerRadius: 30,
                            additionalRadius: -30,
                            color: "gray",
                          },
                        },
                      ]}
                      height={850}
                      width={700}
                      margin={{ right: 300 }}
                      slotProps={{
                        legend: {
                          labelStyle: {
                            fontSize: 12,
                            fill: currentFontColor,
                          },
                        },
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* <div className="flex gap-10 m-4 flex-wrap justify-center">
            <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg p-6 rounded-2xl">
              <div className="flex justify-between items-center gap-2">
                <p className="text-xl font-semibold">Recent Transactions</p>
                <DropDown currentMode={currentMode} />
              </div>
              <div className="mt-10 w-72 md:w-400">
                {recentTransactions.map((item) => (
                  <div key={item.title} className="flex justify-between mt-4">
                    <div className="flex gap-4">
                      <button
                        type="button"
                        style={{
                          color: item.iconColor,
                          backgroundColor: item.iconBg,
                        }}
                        className="text-2xl rounded-lg p-4 hover:drop-shadow-xl"
                      >
                        {item.icon}
                      </button>
                      <div>
                        <p className="text-md font-semibold">{item.title}</p>
                        <p className="text-sm text-gray-400">{item.desc}</p>
                      </div>
                    </div>
                    <p className={`text-${item.pcColor}`}>{item.amount}</p>
                  </div>
                ))}
              </div>
              <div className="flex justify-between items-center mt-5 border-t-1 border-color">
                <div className="mt-3">
                  <Button
                    color="white"
                    bgColor={currentColor}
                    text="Add"
                    borderRadius="10px"
                  />
                </div>

                <p className="text-gray-400 text-sm">36 Recent Transactions</p>
              </div>
            </div>
            <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg p-6 rounded-2xl w-96 md:w-760">
              <div className="flex justify-between items-center gap-2 mb-10">
                <p className="text-xl font-semibold">Sales Overview</p>
                <DropDown currentMode={currentMode} />
              </div>
              <div className="md:w-full overflow-auto">
                <LineChart />
              </div>
            </div>
          </div>

          <div className="flex flex-wrap justify-center">
            <div className="md:w-400 bg-white dark:text-gray-200 dark:bg-secondary-dark-bg rounded-2xl p-6 m-3">
              <div className="flex justify-between">
                <p className="text-xl font-semibold">Weekly Stats</p>
                <button
                  type="button"
                  className="text-xl font-semibold text-gray-500"
                >
                  <IoIosMore />
                </button>
              </div>

              <div className="mt-10 ">
                {weeklyStats.map((item) => (
                  <div
                    key={item.title}
                    className="flex justify-between mt-4 w-full"
                  >
                    <div className="flex gap-4">
                      <button
                        type="button"
                        style={{ background: item.iconBg }}
                        className="text-2xl hover:drop-shadow-xl text-white rounded-full p-3"
                      >
                        {item.icon}
                      </button>
                      <div>
                        <p className="text-md font-semibold">{item.title}</p>
                        <p className="text-sm text-gray-400">{item.desc}</p>
                      </div>
                    </div>

                    <p className={`text-${item.pcColor}`}>{item.amount}</p>
                  </div>
                ))}
                <div className="mt-4">
                  <SparkLine
                    currentColor={currentColor}
                    id="area-sparkLine"
                    height="160px"
                    type="Area"
                    data={SparklineAreaData}
                    width="320"
                    color="rgb(242, 252, 253)"
                  />
                </div>
              </div>
            </div>
            <div className="w-400 bg-white dark:text-gray-200 dark:bg-secondary-dark-bg rounded-2xl p-6 m-3">
              <div className="flex justify-between">
                <p className="text-xl font-semibold">MedicalPro Branding</p>
                <button
                  type="button"
                  className="text-xl font-semibold text-gray-400"
                >
                  <IoIosMore />
                </button>
              </div>
              <p className="text-xs cursor-pointer hover:drop-shadow-xl font-semibold rounded-lg w-24 bg-orange-400 py-0.5 px-2 text-gray-200 mt-10">
                16 APR, 2021
              </p>

              <div className="flex gap-4 border-b-1 border-color mt-6">
                {medicalproBranding.data.map((item) => (
                  <div
                    key={item.title}
                    className="border-r-1 border-color pr-4 pb-2"
                  >
                    <p className="text-xs text-gray-400">{item.title}</p>
                    <p className="text-sm">{item.desc}</p>
                  </div>
                ))}
              </div>
              <div className="border-b-1 border-color pb-4 mt-2">
                <p className="text-md font-semibold mb-2">Teams</p>

                <div className="flex gap-4">
                  {medicalproBranding.teams.map((item) => (
                    <p
                      key={item.name}
                      style={{ background: item.color }}
                      className="cursor-pointer hover:drop-shadow-xl text-white py-0.5 px-3 rounded-lg text-xs"
                    >
                      {item.name}
                    </p>
                  ))}
                </div>
              </div>
              <div className="mt-2">
                <p className="text-md font-semibold mb-2">Leaders</p>
                <div className="flex gap-4">
                  {medicalproBranding.leaders.map((item, index) => (
                    <img
                      key={index}
                      className="rounded-full w-8 h-8"
                      src={item.image}
                      alt=""
                    />
                  ))}
                </div>
              </div>
              <div className="flex justify-between items-center mt-5 border-t-1 border-color">
                <div className="mt-3">
                  <Button
                    color="white"
                    bgColor={currentColor}
                    text="Add"
                    borderRadius="10px"
                  />
                </div>

                <p className="text-gray-400 text-sm">36 Recent Transactions</p>
              </div>
            </div>
            <div className="w-400 bg-white dark:text-gray-200 dark:bg-secondary-dark-bg rounded-2xl p-6 m-3">
              <div className="flex justify-between">
                <p className="text-xl font-semibold">Daily Activities</p>
                <button
                  type="button"
                  className="text-xl font-semibold text-gray-500"
                >
                  <IoIosMore />
                </button>
              </div>
              <div className="mt-10">
                <img className="md:w-96 h-50 " src={product9} alt="" />
                <div className="mt-8">
                  <p className="font-semibold text-lg">React 18 coming soon!</p>
                  <p className="text-gray-400 ">By Johnathan Doe</p>
                  <p className="mt-8 text-sm text-gray-400">
                    This will be the small description for the news you have
                    shown here. There could be some great info.
                  </p>
                  <div className="mt-3">
                    <Button
                      color="white"
                      bgColor={currentColor}
                      text="Read More"
                      borderRadius="10px"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
           */}
        </div>
      )}
    </div>
  );
}

export default Ecommerce;
