import React, { useEffect, useState } from "react";
import useSWR, { preload, useSWRConfig } from "swr";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import Chip from "@mui/material/Chip";
import { TransitionProps } from "@mui/material/transitions";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea, CardActions } from "@mui/material";
import Grid from "@mui/material/Grid"; // Grid version 1
import { IoMdCloseCircleOutline } from "react-icons/io";

//PDF
// import ReactPDF from '@react-pdf/renderer'; //only on server
import ReactDOM from "react-dom";
import { PDFViewer, usePDF } from "@react-pdf/renderer";
import { FaFileDownload } from "react-icons/fa";
import TicketsPDF from "./TicketsPDF";

// Contexts
import { useStateContext } from "./contexts/ContextProvider";

import { fetcherUnAuth, swrOptions } from "./api/index";
const API_ENDPOINT = "/customer";

// // Data
// import { ExistCustomer } from "./api/customer";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Tickets = (props) => {
  const [viewPDF, setViewPDF] = useState(false);
  const [printPdf, setPrintPdf] = useState(false);
  const [open, setOpen] = useState(false);
  const [openViewer, setOpenViewer] = useState(false);
  const [seed, setSeed] = useState(null);

  const { data: operations } = useSWR(
    `${API_ENDPOINT}_operation/identification/${props.identification}`,
    fetcherUnAuth,
    swrOptions
  );
  const [instance, updateInstance] = usePDF({
    document: <TicketsPDF operations={operations} />,
  });

  useEffect(() => {
    updateInstance(<TicketsPDF operations={operations} />);

    return () => {};
  }, [operations]);

  // const {data: operations} = ExistCustomer(props.identification);
  // console.log(operations);

  // useEffect(() => {
  //     if (!operations) {
  //       setMessageGlobalModal("El socio no tiene operaciones registradas.");
  //       setOpenGlobalModal(true);
  //       props.parentCallback(false);
  //     }
  //   return () => {
  //   }
  // }, [operations])

  // Global Context
  const {
    setOpenGlobalModal,
    setMessageGlobalModal,
    setOpenGlobalModalError,
    setMessageGlobalModalError,
  } = useStateContext();

  const handleClose = () => {
    setOpen(false);
    props.parentCallback(false);
  };

  const handleCloseViewer = () => {
    setOpenViewer(false);
    props.parentCallback(false);
  };

  const handleDownload = () => {
    // ReactPDF.render(
    //   <TicketsPDF />,
    //   `${__dirname}/${props.identification}_tickets.pdf`
    // );
    setOpenViewer(true);
    setSeed(Math.random());
  };

  return (
    <>
      {openViewer && (
        <Dialog
          id="dialog-pdf"
          fullScreen
          open={true}
          onClose={handleCloseViewer}
          TransitionComponent={Transition}
        >
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <div className=" flex-row w-full align-middle">
                <div>
                  <div className=" text-right align-middle flex flex-row justify-end text-2xl">
                    <div>
                      <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                      >
                        <CloseIcon />
                      </IconButton>
                    </div>
                  </div>
                </div>
              </div>
            </Toolbar>
          </AppBar>
          <PDFViewer className=" h-screen">
            <TicketsPDF key={seed} operations={operations || null} />
          </PDFViewer>
        </Dialog>
      )}
      <Dialog
        id="dialog-tickets"
        fullScreen
        open={true}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <div className=" flex-row w-full align-middle">
              <div>
                <div className=" text-right align-middle flex flex-row justify-end text-2xl">
                  <div>
                    <IconButton
                      edge="start"
                      color="inherit"
                      onClick={handleClose}
                      aria-label="close"
                    >
                      <CloseIcon />
                    </IconButton>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <IconButton
                      edge="start"
                      color="inherit"
                      onClick={handleDownload}
                      aria-label="download"
                    >
                      <FaFileDownload />
                    </IconButton>
                  </div>
                </div>
                <div className=" flex-row justify-between">
                  <div>
                    <Typography
                      sx={{ ml: 2, flex: 1 }}
                      variant="h6"
                      component="div"
                    >
                      {`BOLETOS POR OPERACION: 
                      ${operations?.customer_first_name}, ${operations?.customer_last_name} | 
                      CI/RUC: ${operations?.customer_identification}`}
                    </Typography>
                    <Typography
                      sx={{ ml: 2, flex: 1 }}
                      variant="h9"
                      component="div"
                    >
                      Presiones las teclas Ctrl+F para buscar.
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
          </Toolbar>
        </AppBar>
        <div className=" items-center justify-between">
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            {operations &&
              operations?.customer_operation.map((item, index) => (
                <Grid item xs={2} sm={4} md={4} key={index}>
                  <Card key={item.customer_identification}>
                    <CardActionArea>
                      <CardContent>
                        <Typography gutterBottom variant="h7" component="div">
                          {`Fecha: ${item.customer_operation_created}`}
                        </Typography>
                        <div>
                          <Typography
                            gutterBottom
                            variant="h7"
                            component="div"
                            style={{ fontWeight: "bold" }}
                          >
                            {`No. Comprobante: ${item.customer_operation_reference}`}
                          </Typography>
                          <Chip
                            label={`${item.hierarchy?.hierarchy_name}`}
                            style={{ fontWeight: "bold" }}
                          />
                        </div>
                        <Typography
                          variant="body3"
                          style={{
                            color: "green",
                            fontWeight: "bold",
                            display: "none",
                          }}
                        >
                          {`Amount USD$ ${item.customer_operation_amount}`}
                        </Typography>
                        <Typography
                          variant="body3"
                          style={{ color: "green", fontWeight: "bold" }}
                        >
                          Usted tiene {item.ticket.length} boletos.
                        </Typography>
                        <Typography gutterBottom variant="h7" component="div">
                          {item.ticket.map((ticket, index) => (
                            <span key={ticket.ticket_id}>
                              {"#"}
                              {String(ticket.ticket_id).padStart(7, "0")}
                              {", "}
                            </span>
                          ))}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              ))}
          </Grid>
        </div>
      </Dialog>
    </>
  );
};

export default Tickets;
