import axios, { AxiosResponse } from "axios";
import { useStateContext } from "../contexts/ContextProvider";
import { throwError } from "@syncfusion/ej2/base";

const baseURL = process.env.REACT_APP_API_BASE_URL;
const apiToken = sessionStorage.getItem("token"); //process.env.REACT_APP_API_TOKEN;

export const config = {
  headers: {
    Authorization: "Bearer " + apiToken,
    "Content-Type": "application/json",
  },
};

export const configForArrays = {
  headers: {
    Authorization: "Bearer " + apiToken,
    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
  },
};

export const configForUpload = {
  headers: {
    Authorization: "Bearer " + apiToken,
    "Content-Type": "multipart/form-data",
  },
};

axios.interceptors.response.use(
  function (response) {
    // Optional: Do something with response data
    return response;
  },
  function (error) {
    // Do whatever you want with the response error here:
    // console.log(`error in intercepto ${error}`);
    // But, be SURE to return the rejected promise, so the caller still has the option of additional specialized handling at the call-site:
    return Promise.reject(error);
  }
);

export const api = axios.create({
  baseURL,
});

export const swrOptions = {
  revalidateOnReconnect: true,
  revalidateOnFocus: true,
  dedupingInterval: 750, // you can only refetched after x milisecons
  refreshInterval: 15 * 1000, // refetch data every x miliseconds
};

export const fetcher = async (url) => {
  // console.log(`url fetcher (${url})`);
  try {
    return await api
      .get(url, config)
      .then((response) => {
        // console.log(`response fetcher (${url}): ${JSON.stringify(response)}`);
        if (response.status !== 200 && response.statusText !== "OK") {
          throw new Error(response.statusText);
        } else {
          return response.data;
        }
      })
      .catch((error) => {
        throw new Error(error.response.data);
      });
  } catch (error) {
    if (error.response) {
      throw new Error(error.message);
    } else if (error.request) {
      throw new Error(error.message);
    } else {
      throw new Error(error.message);
    }
  }
};

export const fetcherUnAuth = async (url) => {
  // console.log(url)
  try {
    return await api
      .get(url)
      .then((response) => {
        if (response.status !== 200 && response.statusText !== "OK") {
          throw new Error(response.statusText);
        } else {
          return response.data;
        }
      })
      .catch((error) => {
        throw new Error(error.response.data);
      });
  } catch (error) {
    if (error.response) {
      throw new Error(error.message);
    } else if (error.request) {
      throw new Error(error.message);
    } else {
      throw new Error(error.message);
    }
  }
};

export const fetcherPayload = async (url, payload) => {
  try {
    const newConfig = {
      params: payload,
      headers: {
        Authorization: "Bearer " + apiToken,
        "Content-Type": "application/json",
      },
    };

    return await api
      .get(url, newConfig)
      .then((response) => {
        if (response.status !== 200 && response.statusText !== "OK") {
          throw new Error(response.statusText);
        } else {
          return response.data;
        }
      })
      .catch((error) => {
        throw new Error(error.response.data);
      });
  } catch (error) {
    if (error.response) {
      throw new Error(error.message);
    } else if (error.request) {
      throw new Error(error.message);
    } else {
      throw new Error(error.message);
    }
  }
};

export const getter = async (url) => {
  // console.log(`url getter: ${url}`);
  try {
    return await api
      .get(url, config)
      .then(async (response) => {
        // console.log(`response ${JSON.stringify(response)}`);
        if (response.status !== 200 && response.statusText !== "OK") {
          // console.log(`response !== 200 ${JSON.stringify(response)}`);
          throw new Error(response.statusText);
        } else {
          // console.log(`response === 200 ${JSON.stringify(response)}`);
          return response.data;
        }
      })
      .catch((error) => {
        throw new Error(error.response.data);
      });
  } catch (error) {
    // console.log(`catch del try ${JSON.stringify(error)}`);
    if (error.response) {
      throw new Error(error.message);
    } else if (error.request) {
      throw new Error(error.message);
    } else {
      throw new Error(error.message);
    }
  }
};

export const poster = async (url, payload) => {
  const posterConfig = !Array.isArray(payload) ? config : configForArrays;
  // console.log(posterConfig);

  try {
    return await api
      .post(url, payload, posterConfig)
      .then(async (response) => {
        if (response.status !== 200 && response.statusText !== "OK") {
          // console.log(`response !== 200 ${JSON.stringify(response)}`);
          throw new Error(response.statusText);
        } else {
          // console.log(`response === 200 ${JSON.stringify(response)}`);
          return response.data;
        }
      })
      .catch((error) => {
        throw new Error(error.response.data);
      });
  } catch (error) {
    // console.log(`catch del try ${JSON.stringify(error)}`);
    if (error.response) {
      throw new Error(error.message);
    } else if (error.request) {
      throw new Error(error.message);
    } else {
      throw new Error(error.message);
    }
  }
};

export const posterLogin = async (url, payload) => {
  try {
    return await api
      .post(url, payload)
      .then(async (response) => {
        // console.log(`response ${JSON.stringify(response)}`);
        if (response.status !== 200 && response.statusText !== "OK") {
          // console.log(`response !== 200 ${JSON.stringify(response)}`);
          throw new Error(response.statusText);
        } else {
          // console.log(`response === 200 ${JSON.stringify(response)}`);
          return response.data;
        }
      })
      .catch((error) => {
        // console.log(`error ${JSON.stringify(error)}`);
        throw new Error(error.response.data);
      });
  } catch (error) {
    // console.log(`catch del try ${JSON.stringify(error)}`);
    if (error.response) {
      throw new Error(error.message);
    } else if (error.request) {
      throw new Error(error.message);
    } else {
      throw new Error(error.message);
    }
  }
};

export const updater = async (url, payload) => {
  const updaterConfig = !Array.isArray(payload) ? config : configForArrays;

  try {
    return await api
      .put(url, payload, updaterConfig)
      .then(async (response) => {
        if (response.status !== 200 && response.statusText !== "OK") {
          // console.log(`response !== 200 ${JSON.stringify(response)}`);
          throw new Error(response.statusText);
        } else {
          // console.log(`response === 200 ${JSON.stringify(response)}`);
          return response.data;
        }
      })
      .catch((error) => {
        throw new Error(error.response.data);
      });
  } catch (error) {
    // console.log(`catch del try ${JSON.stringify(error)}`);
    if (error.response) {
      throw new Error(error.message);
    } else if (error.request) {
      throw new Error(error.message);
    } else {
      throw new Error(error.message);
    }
  }
};

export const remover = async (url) => {
  try {
    return await api
      .delete(url, config)
      .then(async (response) => {
        if (response.status !== 200 && response.statusText !== "OK") {
          // console.log(`response !== 200 ${JSON.stringify(response)}`);
          throw new Error(response.statusText);
        } else {
          // console.log(`response === 200 ${JSON.stringify(response)}`);
          return response.data;
        }
      })
      .catch((error) => {
        throw new Error(error.response.data);
      });
  } catch (error) {
    // console.log(`catch del try ${JSON.stringify(error)}`);
    if (error.response) {
      throw new Error(error.message);
    } else if (error.request) {
      throw new Error(error.message);
    } else {
      throw new Error(error.message);
    }
  }
};

export default {
  fetcher,
  fetcherUnAuth,
  fetcherPayload,
  getter,
  poster,
  posterLogin,
  updater,
  remover,
  swrOptions,
  config,
  configForArrays,
  configForUpload,
};
