import React , {useEffect, useState} from 'react'
import useSWR from 'swr';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


import {aspi, swrOptions, fetcher} from '../api'

const API_ENDPOINT = "/hierarchy";

const HierarchySelect = (props) => {
    const {data: hierarchies, isLoading, isValidating, error} = useSWR(`${API_ENDPOINT}/all`, fetcher, swrOptions);
    // console.log(JSON.stringify(hierarchies));

    const [hierarchyId, setHierarchyId] = useState(props.hierarchyId);

    const handleChange = (event) => {
        props.parentCallback(event.target.value);
        event.preventDefault();
        setHierarchyId(event.target.value);
    };

    return (
    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
      <InputLabel id="hierarchyId-label">Jerarquia</InputLabel>
      <Select
        labelId="hierarchyId-label"
        id="hierarchyId"
        value={hierarchyId}
        label="Jerarquia"
        onChange={handleChange}
      >
        {hierarchies && 
            hierarchies.map((hierarchy)=>(
                <MenuItem key={hierarchy?.hierarchy_name} value={hierarchy?.hierarchy_id}>{hierarchy?.hierarchy_name}</MenuItem>
            ))
        }

        {/* <MenuItem value="">
          <em>None</em>
        </MenuItem>
        <MenuItem value={20}>Twenty</MenuItem>
        <MenuItem value={30}>Thirty</MenuItem> */}

      </Select>
    </FormControl>
  )
}

export default HierarchySelect