import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import SendIcon from "@mui/icons-material/Send";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Modal from "@mui/material/Modal";
import Slide from "@mui/material/Slide";

import "./Login.css";

import { LoginAction } from "../api/auth";

// Contexts
import { useStateContext } from "../contexts/ContextProvider";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

// Transitions
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

/* BACKDROP */
const handleBackdropClick = (e) => {
  //these fail to keep the modal open
  e.stopPropagation();
  return false;
};

const Login = (props) => {
  const { onClose, value: valueProp, open, ...other } = props;

  const [openDialog, setOpenDialog] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [messageModal, setMessageModal] = useState("");
  const [inputEmail, setInputEmail] = useState("");
  const [errorEmail, setErrorEmail] = useState(false);
  const [inputPassword, setInputPassword] = useState("");
  const [errorPassword, setErrorPassword] = useState(false);

  const {
    token,
    setToken,
    setUserLogged,
    setOpenGlobalModal,
    setMessageGlobalModal,
  } = useStateContext();

  const navigate = useNavigate();

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    cleanFields();
    setOpenDialog(false);
    navigate("/", {replace: true});
  };

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handleInputEmail = (event) => {
    const value = event.target.value;
    if (value.length == 0) {
      //setInputUsername(value.substring(0, 100));
      setErrorEmail(true);
    } else {
      setErrorEmail(false);
      setInputEmail(value);
    }
  };

  const handleInputPassword = (event) => {
    const value = event.target.value;
    if (value.length == 0) {
      //setInputUsername(value.substring(0, 100));
      setErrorPassword(true);
    } else {
      setErrorPassword(false);
      setInputPassword(value);
    }
  };

  const cleanFields = () => {
    setInputEmail("");
    setInputPassword("");
  };

  return (
    <div>
      <div className="banner-container">
        <div className="text-center">
          <div>
            <Dialog
              sx={{ "& .MuiDialog-paper": { width: "100%", maxHeight: 500 } }}
              maxWidth="xs"
              {...other}
              open={openDialog}
              onClick={(e) => e.stopPropagation()}
              disableEscapeKeyDown={true}
              onClose={handleClose}
              PaperProps={{
                component: "form",
                onSubmit: (event) => {
                  event.preventDefault();
                  const formData = new FormData(event.currentTarget);
                  const formJson = Object.fromEntries(formData.entries());

                  // Validation
                  const _email = formJson.email;
                  const _password = formJson.password;

                  // Enviar al ENDPOINT y espeerar respuesta antes de cerrar
                  const payload = {
                    email: _email,
                    password: _password,
                  };
                  LoginAction(payload)
                  .then((res) => {
                    // console.log(`res = ${JSON.stringify(res)}`);

                    setUserLogged(res);
                    setToken(res.user_jwt_token);
                    setMessageGlobalModal("Credenciales validas.");
                    setOpenGlobalModal(true);

                    //clean fields
                    cleanFields();
                    handleClose();

                    // refresh
                    window.location.reload(false);

                  })
                  .catch((error) => {
                    // console.log(`error = ${JSON.stringify(error)}`);
                    setMessageModal(
                        `Error al intentar autenticar al socio [${error.message}].\r\nRevise credenciales por favor.`
                        );
                        setOpenModal(true);
                      });
                      
                      navigate("/", {replace: true});
                      
                    },
              }}
            >
              <DialogTitle>LOGIN</DialogTitle>
              <DialogContent>
                <div>
                  <Modal
                    keepMounted
                    open={openModal}
                    onClose={handleCloseModal}
                    aria-labelledby="keep-mounted-modal-title"
                    aria-describedby="keep-mounted-modal-description"
                  >
                    <Box sx={style}>
                      <Typography
                        id="keep-mounted-modal-title"
                        variant="h6"
                        component="h2"
                      >
                        {messageModal}
                      </Typography>
                    </Box>
                  </Modal>
                </div>
                <DialogContentText></DialogContentText>
                <TextField
                  autoFocus
                  required
                  margin="dense"
                  id="email"
                  name="email"
                  label="Correo"
                  type="email"
                  fullWidth
                  variant="standard"
                  value={inputEmail}
                  onChangeCapture={handleInputEmail}
                  error={errorEmail}
                  helperText="Debe ingresar un email valido."
                />
                <TextField
                  autoFocus
                  required
                  margin="dense"
                  id="password"
                  name="password"
                  label="Clave"
                  type="password"
                  fullWidth
                  variant="standard"
                  value={inputPassword}
                  onChangeCapture={handleInputPassword}
                  error={errorPassword}
                  helperText="Debe ingresar una clave valida."
                />
              </DialogContent>
              <DialogActions>
                <Button variant="outlined" color="error" onClick={handleClose}>
                  Cancelar
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  endIcon={<SendIcon />}
                >
                  Entrar
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
