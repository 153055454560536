import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { getLocalISOTime } from "../utils/formatter";
import { AiOutlineFileSearch } from "react-icons/ai";

import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

// Data
import { GetLotteries } from "../api/lottery";

const FilterData = (props) => {
  const today = new Date();
  const [dateFrom, setDateFrom] = useState(dayjs(today.toISOString()));
  const [dateTo, setDateTo] = useState(dayjs(today.toISOString()));
  const [lotteryId, setLotteryId] = useState("");
  const { data: allLotteries, error, isLoading, isValidating } = GetLotteries();

  const handleChangeDropDown = (event) => {
    setLotteryId(event.target.value);
  };

  const handleClickSearch = async () => {
    props.parentCallback({
      from: getLocalISOTime(dateFrom),
      to: getLocalISOTime(dateTo),
      lotteryId,
    });
  };

  return (
    <div className="flex flex-row m-2 p-2 bg-white bg-opacity-80 rounded-lg justify-end items-center align-middle ">
      <FormControl className=" w-1/5">
        <InputLabel id="demo-simple-select-label">Sorteo</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={lotteryId}
          label="Sorteo"
          onChange={handleChangeDropDown}
        >
          {allLotteries &&
            allLotteries.map((lottery) => (
              <MenuItem key={lottery?.lottery_id} value={lottery?.lottery_id}>
                {lottery.lottery_name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      &nbsp;&nbsp;
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={["DateTimePicker"]}>
          <div className="flex flex-row justify-end">
            <DateTimePicker
              clearable
              ampm={false}
              label="Fecha desde"
              value={dateFrom}
              onChange={(newValue) => setDateFrom(newValue)}
            />
            &nbsp;&nbsp;&nbsp;
            <DateTimePicker
              clearable
              ampm={false}
              label="Fecha hasta"
              value={dateTo}
              onChange={(newValue) => setDateTo(newValue)}
            />
            &nbsp;&nbsp;&nbsp;
            <button
              type="button"
              style={{
                color: "#03C9D7",
                backgroundColor: "#E5FAFB",
              }}
              className="text-2xl opacity-0.9 rounded-lg p-4 hover:drop-shadow-xl"
            >
              <span style={{ fontSize: "36px" }}>
                <AiOutlineFileSearch onClick={handleClickSearch} />
              </span>
            </button>
          </div>
        </DemoContainer>
      </LocalizationProvider>
    </div>
  );
};

export default FilterData;
