const  usDollarFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

export const formatCurrency =(number)=>{
    return usDollarFormatter.format(number);
}

export const getLocalISOTime = (twDate) => {
    var d = new Date(twDate);
    var localOffset = d.getTimezoneOffset() * 60000; // Offset in milliseconds
    var newdate = new Date(d.getTime() - localOffset);
    // return newdate.toISOString().replace(".000", "");
    return newdate.toISOString();
}


export default {formatCurrency, getLocalISOTime};