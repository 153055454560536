import React, { useState, useEffect, useRef } from "react";
import Header from "../components/Header";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import {
  MdAdd,
  MdCreate,
  MdRemove,
  MdOutlineDelete,
  MdModeEdit,
  MdFileUpload,
  MdUploadFile,
  MdUpload,
  MdAccountTree,
} from "react-icons/md";


import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import SendIcon from "@mui/icons-material/Send";
import Modal from "@mui/material/Modal";
import Slide from "@mui/material/Slide";

import validator from "ecuador-validator";
import Papa from "papaparse";

import { DataGrid } from "@mui/x-data-grid";

// DropDown
import {PermissionSelect} from '../components'

// Data
import {
  PreGetRoles,
  GetRoles,
  SaveRole,
  RemoveRole,
  UpdateRole,
  SaveManyRoles,
} from "../api/roles";

// Contexts
import { useStateContext } from "../contexts/ContextProvider";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

// Transitions
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// load CSV
const allowedExtensions = ["csv"];

PreGetRoles();

//************************************************************************************************************ */
export function Roles(props) {
  const { data, error, isLoading, isValidating } = GetRoles();
  // console.log(JSON.stringify(data));

  const [seed, setSeed] = useState(1);
  const resetTable = () => {
    setSeed(Math.random());
  };

  // Global Context
  const {
    setOpenGlobalModal,
    setMessageGlobalModal,
    setOpenGlobalModalError,
    setMessageGlobalModalError,
  } = useStateContext();

  const { onClose, value: valueProp, open, ...other } = props;

  const [openDialog, setOpenDialog] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const [messageModal, setMessageModal] = useState("");

  const [inputRoleName, setInputRoleName] = useState("");
  const [errorRoleName, setErrorRoleName] = useState(false);

  const [inputRoleDesc, setInputRoleDesc] = useState("");
  const [errorRoleDesc, setErrorRoleDesc] = useState(false);

  const [inputPermissionIds, setInputPermissionIds] = useState([])
  const [inputPermissionNames, setInputPermissionNames] = useState([])

  const [showRemove, setShowRemove] = useState(false);
  const [showEdit, setShowEdit] = useState(false);

  const [openConfirmation, setOpenConfirmation] = useState(false);

  const [roleSelected, setRoleSelected] = useState(null);

  // Context states
  const { currentColor, currentMode } = useStateContext();

  const joinPermissions = (params) =>{
    return `${params.row.role_permission.map((permission) => `${permission.permission.permission_name}`).join(' ● ')}`
  }

  const splitPermissions = (params) =>{
    return params.row.role_permission.map((permission) => `${permission.permission.permission_name}`).join(',').split(',');
  }

  // Custom Button
  const NavButton = ({
    title,
    customFunc,
    icon,
    color,
    dotColor,
    visible = true,
  }) => {
    if (!visible) return <></>;
    return (
      <Tooltip content={title} placement="bottom-start">
        <button
          type="button"
          onClick={customFunc}
          style={{ color }}
          className="relative text-4xl rounded-full p-3 hover:bg-light-gray"
        >
          <span
            style={{ background: dotColor }}
            className="absolute inline-flex rounded-full h-2 w-2 right-2 top-2"
          />
          {icon}
        </button>
      </Tooltip>
    );
  };

  // Colums
  const columns = [
    { field: "role_id", headerName: "ID", width: 70 },
    { field: "role_name", headerName: "Rol Nombre", width: 250 },
    { field: "role_desc", headerName: "Rol Descripción", width: 250 },

    // Base field value from expresion
    { field: "role_permissions", valueGetter: (params) => {
        return joinPermissions(params);
      }, headerName: "Permisos", width: 500 
    },

    {
      field: "click_edit",
      headerName: "Modificar",
      width: 65,
      renderCell: (params) => {
        return (
          <div className="flex justify-center items-center w-full text-2xl">
            <button onClick={() => handleClickEdit(params)}>
              <MdModeEdit />
            </button>
          </div>
        );
      },
    },
    {
      field: "click_remove",
      headerName: "Eliminar",
      width: 65,
      renderCell: (params) => {
        return (
          <div className="flex justify-center items-center w-full text-2xl">
            <button onClick={() => handleClickRemove(params)}>
              <MdOutlineDelete />
            </button>
          </div>
        );
      },
    },
    // {
    //   field: "age",
    //   headerName: "Age",
    //   type: "number",
    //   width: 90,
    // },
    // {
    //   field: "fullName",
    //   headerName: "Full name",
    //   description: "This column has a value getter and is not sortable.",
    //   sortable: false,
    //   width: 160,
    //   valueGetter: (params) =>
    //     `${params.row.firstName || ""} ${params.row.lastName || ""}`,
    // },
  ];


  // *************** handlers
  const handleClickRoles = (params) => {
    setRoleSelected({
      role_id: params.row.role_id,
      role_name: params.row.role_name,
      role_desc: params.row.role_desc,
    });

    setOpenConfirmation(true);
  };

  const handleClickRemove = (params) => {
    setRoleSelected({
      role_id: params.row.role_id,
      role_name: params.row.role_name,
      role_desc: params.row.role_desc,
    });

    setOpenConfirmation(true);
  };

  const handleRemoving = () => {
    RemoveRole(roleSelected.role_id, roleSelected)
      .then((response) => {
        setMessageGlobalModal("El rol se elimino correctamente.");
        setOpenGlobalModal(true);
      })
      .catch((error) => {
        setMessageModal(
          `Error al intentar eliminar rol [${error.message}].`
        );
        setOpenGlobalModal(true);
      });

    handleCloseConfirmation();
  };

  const handleClickEdit = (params) => {
    setRoleSelected({
      role_id: params.row.role_id,
      role_name: params.row.role_name,
      role_desc: params.row.role_desc,
    });

    setInputPermissionNames(splitPermissions(params));

    // Load form dilog with custom data
    setInputRoleName(params.row.role_name);
    setInputRoleDesc(params.row.role_desc);
    setOpenDialog(true);
  };

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setRoleSelected(null);
    cleanFields();
    setOpenDialog(false);
  };

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handleInputRoleName = (event) => {
    const value = event.target.value;
    if (value.length < 1 || value.length > 50) {
      setInputRoleName(value.substring(0, 50));
      // setErrorRoleName(true)
    } else {
      setErrorRoleName(false);
      setInputRoleName(value);
    }
  };

  const handleInputRoleDesc = (event) => {
    const value = event.target.value;
    if (value.length < 1 || value.length > 255) {
      setInputRoleDesc(value.substring(0, 255));
      // setErrorRoleDesc(true)
    } else {
      setErrorRoleDesc(false);
      setInputRoleDesc(value);
    }
  };

  const handleCloseConfirmation = () => {
    setRoleSelected(null);
    setOpenConfirmation(false);
  };
  const handleOpenConfirmation = () => {
    setOpenConfirmation(true);
  };

  const cleanFields = () => {
    setInputRoleName("");
    setInputRoleDesc("");
  };

  const handleCallbackPermission = (newPermissionIds) => {
    // console.log(`newPermissionIds ${newPermissionIds}`);
    setInputPermissionIds(newPermissionIds);
  };


  /* BACKDROP */
  const handleBackdropClick = (e) => {
    //these fail to keep the modal open
    e.stopPropagation();
    return false;
  };

  return (
    <div className="m-2 md:m-10 p-2 md:p-10 bg-white bg-opacity-80 rounded-3xl ">
      <div className="flex flex-row justify-between items-center">
        <Header category="Página" title="Roles" />
        <div>
          <NavButton
            title="Agregar Rol"
            customFunc={() => handleClickOpen()}
            color={currentColor}
            icon={<MdAdd />}
          />
        </div>
      </div>
      <div>
        <div>
          {/* DIALOG CONFIRMATION  */}
          <Dialog
            open={openConfirmation}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleCloseConfirmation}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle>{`Eliminar a ${roleSelected?.role_name} ? `}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                Desea eliminar este rol de la plataforma ?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseConfirmation}>Cancelar</Button>
              <Button onClick={handleRemoving}>Aceptar</Button>
            </DialogActions>
          </Dialog>

          {/* *** FORM DIALOG *** */}
          <Dialog
            sx={{ "& .MuiDialog-paper": { width: "100%", maxHeight: 500 } }}
            maxWidth="xs"
            {...other}
            open={openDialog}
            onClick={(e) => e.stopPropagation()}
            disableEscapeKeyDown={true}
            onClose={handleClose}
            PaperProps={{
              component: "form",
              onSubmit: (event) => {
                event.preventDefault();
                const formData = new FormData(event.currentTarget);
                const formJson = Object.fromEntries(formData.entries());

                // Validation
                const _roleName = formJson.roleName;
                const _roleDesc = formJson.roleDesc;

                // Enviar al ENDPOINT y espeerar respuesta antes de cerrar
                let payload;
                if (!roleSelected) {

                  /*  NEW ROLE */

                  payload = {
                    roleName: _roleName,
                    roleDesc: _roleDesc,
                    rolePermissions: inputPermissionIds,
                  };
                  // console.log(JSON.stringify(payload))

                  SaveRole(payload)
                    .then((res) => {
                      setMessageGlobalModal(
                        "El Rol se grabo correctamente."
                      );
                      setOpenGlobalModal(true);
                      handleClose();

                      //clean fields
                      cleanFields();
                      handleClose();
                    })
                    .catch((error) => {
                      setMessageModal(
                        `Error al intentar grabar rol [${error.message}].\r\nRevise por favor.`
                      );
                      setOpenModal(true);
                    });
                } else {

                  /* UPDATE USER */

                  payload = {
                    roleId: roleSelected.role_id,
                    roleName: _roleName,
                    roleDesc: _roleDesc,
                    rolePermissions: inputPermissionIds,
                  };
                  // console.log(JSON.stringify(payload))

                  UpdateRole(payload)
                    .then((res) => {
                      setMessageGlobalModal(
                        "El Rol se actualizo correctamente."
                      );
                      setOpenGlobalModal(true);
                      handleClose();

                      //clean fields
                      cleanFields();
                      handleClose();
                    })
                    .catch((error) => {
                      setMessageGlobalModal(
                        `Error al intentar actualizar rol [${error.message}].\r\nRevise por favor.`
                      );
                      setOpenGlobalModal(true);
                    });
                }
                resetTable();
              },
            }}
          >
            <DialogTitle>
              {!roleSelected ? "AGREGAR ROL" : "MODIFICAR ROL"}
            </DialogTitle>
            <DialogContent>
              <div>
                {/* MODAL */}
                <Modal
                  keepMounted
                  open={openModal}
                  onClose={handleCloseModal}
                  aria-labelledby="keep-mounted-modal-title"
                  aria-describedby="keep-mounted-modal-description"
                >
                  <Box sx={style}>
                    <Typography
                      id="keep-mounted-modal-title"
                      variant="h6"
                      component="h2"
                    >
                      {messageModal}
                    </Typography>
                  </Box>
                </Modal>
              </div>
              {/* <DialogContentText>
                NUEVO SOCIO
              </DialogContentText> */}

              <PermissionSelect inputPermissionNames={inputPermissionNames} parentCallback={handleCallbackPermission} />


              {/* <TextField
                autoFocus
                required
                margin="dense"
                id="hierarchyId"
                name="hierarchyId"
                label="Hierarchy"
                type="number"
                fullWidth
                variant="standard"
                value={inputHierarchyId}
                // onChangeCapture={handleInputHierarchyId}
                error={errorHierarchyId}
                helperText="Debe ingresar una jerarquia valida."
              /> */}

              <TextField
                autoFocus
                required
                margin="dense"
                id="roleName"
                name="roleName"
                label="Rol Nombre"
                type="text"
                fullWidth
                variant="standard"
                value={inputRoleName}
                onChangeCapture={handleInputRoleName}
                error={errorRoleName}
                helperText="Debe ingresar un nombre de rol valido."
              />

              <TextField
                required
                margin="dense"
                id="roleDesc"
                name="roleDesc"
                label="Rol Descripción"
                type="text"
                fullWidth
                variant="standard"
                value={inputRoleDesc}
                onChangeCapture={handleInputRoleDesc}
                error={errorRoleDesc}
                helperText="Debe ingresar una descripcion valida."
              />
            </DialogContent>
            <DialogActions>
              <Button variant="outlined" color="error" onClick={handleClose}>
                Cancelar
              </Button>
              <Button type="submit" variant="contained" endIcon={<SendIcon />}>
                Grabar
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        {data && (
          <Box sx={{ width: "100%" }}>
            <DataGrid
              key={seed}
              rows={data}
              columns={columns}
              getRowId={(row) => row?.role_id}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 5 },
                },
              }}
              pageSizeOptions={[5, 10]}
              // checkboxSelection
            />
          </Box>
        )}
      </div>
    </div>
  );
}

export default Roles;
