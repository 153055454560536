import React, { useEffect } from "react";

import { BrowserRouter, Routes, Router, Route } from "react-router-dom";
import { FiSettings } from "react-icons/fi";
// import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import Tooltip from "@mui/material/Tooltip";

import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import "./App.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import MyBackgroundImage from "./assets/Portada-boletos.png";

import { Navbar, Footer, Sidebar, ThemeSettings } from "./components";
import {
  Ecommerce,
  Lottery,
  Lotteries,
  Customers,
  Users,
  Login,
  Roles,
  Operations,
  OperationsByHierarchy,
  OperationsByUser,
  Forgot,
  Unauthorized,
  // Calendar,
  // Stacked,
  // Pyramid,
  // Kanban,
  // Line,
  // Area,
  // Bar,
  // Pie,
  // Financial,
  // ColorPicker,
  // ColorMapping,
  // Editor,
} from "./pages";

import HomePage from "./HomePage";

// Auth Context
import { ProtectedRoute } from "./routes/ProtectedRoute";
import { useStateContext } from "./contexts/ContextProvider";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#FFFFCC",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const styleError = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#EF7D7D",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const App = () => {
  const {
    setCurrentColor,
    setCurrentMode,
    currentMode,
    activeMenu,
    currentColor,
    themeSettings,
    setThemeSettings,
    openGlobalModal,
    setOpenGlobalModal,
    messageGlobalModal,
    openGlobalModalError,
    setOpenGlobalModalError,
    messageGlobalModalError,
    token,
    setToken,
  } = useStateContext();

  useEffect(() => {
    const currentThemeColor = localStorage.getItem("colorMode");
    const currentThemeMode = localStorage.getItem("themeMode");
    if (currentThemeColor && currentThemeMode) {
      setCurrentColor(currentThemeColor);
      setCurrentMode(currentThemeMode);
    }
  }, []);

  const handleCloseModal = () => setOpenGlobalModal(false);
  const handleCloseModalError = () => setOpenGlobalModalError(false);

  const SideBarContainer = () => {
    return activeMenu ? (
      <div className="w-72 fixed sidebar dark:bg-secondary-dark-bg bg-white ">
        <Sidebar />
      </div>
    ) : (
      <div className="w-0 dark:bg-secondary-dark-bg">
        <Sidebar />
      </div>
    );
  };

  const externalImage =
    "https://sp-ao.shortpixel.ai/client/to_auto,q_glossy,ret_img/https://coopminga.com/wp-content/uploads/2022/03/Agencias-Minga.png";

  return (
    <div
      className={currentMode === "Dark" ? "dark" : ""}
      style={{
        backgroundImage: `url(${MyBackgroundImage})`,
        // backgroundImage: `url(${externalImage})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        height: "500px",
      }}
    >
      <BrowserRouter>
        <div className="flex relative dark:bg-main-dark-bg">
          <div className="fixed right-4 bottom-4" style={{ zIndex: "1000" }}>
            <Tooltip content="Settings" placement="bottom-start">
              <button
                type="button"
                onClick={() => setThemeSettings(true)}
                style={{ background: currentColor, borderRadius: "50%" }}
                className="text-3xl text-white p-3 hover:drop-shadow-xl hover:bg-light-gray"
              >
                <FiSettings />
              </button>
            </Tooltip>
          </div>
          {token ? <SideBarContainer /> : <></>}
          <div
            className={
              !token
                ? "w-full"
                : activeMenu
                ? "dark:bg-main-dark-bg  bg-main-bg min-h-screen md:ml-72 w-full  "
                : "bg-main-bg dark:bg-main-dark-bg  w-full min-h-screen flex-2 "
            }
          >
            <div className="fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full ">
              <Navbar />
            </div>
            <div>
              {themeSettings && <ThemeSettings />}

              {/* *** MODAL ERROR *** */}
              <div>
                <Modal
                  id="nodal_error"
                  keepMounted
                  open={openGlobalModalError}
                  onClose={handleCloseModalError}
                  aria-labelledby="keep-mounted-modal-title"
                  aria-describedby="keep-mounted-modal-description"
                >
                  <Box sx={styleError}>
                    <Typography
                      id="keep-mounted-modal-title"
                      variant="h6"
                      component="h2"
                    >
                      {messageGlobalModalError}
                    </Typography>
                  </Box>
                </Modal>
              </div>

              {/* *** MODAL NORMAL *** */}
              <div>
                <Modal
                  keepMounted
                  open={openGlobalModal}
                  onClose={handleCloseModal}
                  aria-labelledby="keep-mounted-modal-title"
                  aria-describedby="keep-mounted-modal-description"
                >
                  <Box sx={style}>
                    <Typography
                      id="keep-mounted-modal-title"
                      variant="h6"
                      component="h2"
                    >
                      {messageGlobalModal}
                    </Typography>
                  </Box>
                </Modal>
              </div>

              {/* The order this routes is important, is how browser evaluates the match */}

              {/* Public  */}
              {/* <Route path="/about" element={<About />} />
                <Route path="/contactus" element={<ContatUs />} /> */}
              <Routes>
                <Route path="/unauthorized" element={<Unauthorized />} />

                {/* NotAuthenticatedOnly */}
                {!token && <Route exact path="/" element={<HomePage />} />}
                {!token && <Route exact path="/login" element={<Login />} />}
                {!token && (
                  <Route exact path="/forgot/:token" element={<Forgot />} />
                )}

                {/* AuthenticatedOnly */}
                <Route exact path="/" element={<ProtectedRoute />}>
                  <Route exact path="/dashboard" element={<Ecommerce />} />
                  <Route exact path="/lottery" element={<Lottery />} />
                  <Route exact path="/lotteries" element={<Lotteries />} />
                  <Route exact path="/user" element={<Users />} />
                  <Route exact path="/customer" element={<Customers />} />
                  <Route exact path="/role" element={<Roles />} />
                  <Route exact path="/operation" element={<Operations />} />
                  <Route
                    exact
                    path="/operationAgency"
                    element={<OperationsByHierarchy />}
                  />
                  <Route
                    exact
                    path="/operationUser"
                    element={<OperationsByUser />}
                  />
                </Route>
              </Routes>
            </div>
            <Footer />
          </div>
        </div>
      </BrowserRouter>
    </div>
  );
};

export default App;
