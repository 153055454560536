import useSWR, { preload, useSWRConfig } from "swr";
import {
  getter,
  fetcher,
  fetcherUnAuth,
  fetcherPayload,
  poster,
  updater,
  remover,
  swrOptions,
} from "./index";

const API_ENDPOINT = "/dashboard";


export const GetAllEarnings = (props) => {
  if (!props) return null;
  try {
    return getter(`${API_ENDPOINT}/${props?.lotteryId}/all?from=${props?.from}&to=${props?.to}`);
  } catch (error) {
    throw new Error(error);
  }
};

export const GetAllEarningsDay = (props) => {
  if (!props) return null;
  try {
    return getter(`${API_ENDPOINT}/${props?.lotteryId}/day?from=${props?.from}&to=${props?.to}`);
  } catch (error) {
    throw new Error(error);
  }
};

export const GetAllEarningsHierarchy = (props) => {
  if (!props) return null;
  try {
    return getter(`${API_ENDPOINT}/${props?.lotteryId}/hierarchy?from=${props?.from}&to=${props?.to}`);
  } catch (error) {
    throw new Error(error);
  }
};

export const GetAllEarningsUser = (props) => {
  if (!props) return null;
  try {
    return getter(`${API_ENDPOINT}/${props?.lotteryId}/user?from=${props?.from}&to=${props?.to}`);
  } catch (error) {
    throw new Error(error);
  }
};

export default {
  GetAllEarnings,
  GetAllEarningsDay,
  GetAllEarningsHierarchy,
  GetAllEarningsUser
};
