import React, { useState, useEffect, useRef } from "react";
import Header from "../components/Header";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { CustomButton } from "../components/CustomButton";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { FaWhatsapp } from "react-icons/fa6";
import { AiOutlineFileSearch } from "react-icons/ai";

// Data
import {
  GetOperationExtendedHierarchy,
  ResendOperation,
} from "../api/operation";
import { FilterData } from "../components";

// Contexts
import { useStateContext } from "../contexts/ContextProvider";
import { Today } from "@mui/icons-material";
import { useStaticPicker } from "@mui/x-date-pickers/internals";
import { getLocalISOTime } from "../utils/formatter";

const OperationsByHierarchy = () => {
  const today = new Date();
  const [filter, setFilter] = useState(null);
  const [seed, setSeed] = useState(1);
  const [dataFetched, setDataFetched] = useState(null);
  const [dateFrom, setDateFrom] = useState(dayjs(today.toISOString()));
  const [dateTo, setDateTo] = useState(dayjs(today.toISOString()));
  const [searching, setSearching] = useState(false);

  // Global Context
  const {
    setOpenGlobalModal,
    setMessageGlobalModal,
    setOpenGlobalModalError,
    setMessageGlobalModalError,
  } = useStateContext();

  const resetTable = () => {
    setSeed(Math.random());
  };

  useEffect(() => {
    (async () => {
      await GetOperationExtendedHierarchy({
        dateFrom: filter?.from,
        dateTo: filter?.to,
        lotteryId: filter?.lotteryId,
      })
        .then((data) => setDataFetched(data))
        .catch((error) => console.log(error));
      return async () => {
        resetTable();
      };
    })();
  }, [seed]);

  //Toolbar
  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  };

  // Colums
  const columns = [
    {
      field: "hierarchy_id_origin",
      type: "number",
      headerName: "HIERARCHYIDORIGIN",
      width: 0,
    },
    {
      field: "hierarchyName",
      headerName: "AGENCIA",
      description: "This column has a value getter and is not sortable.",
      sortable: true,
      width: 300,
      valueGetter: (params) => `${params.row.hierarchy_name}`,
    },
    {
      field: "hierarchyAmount",
      type: "number",
      headerName: "MONTO USD$",
      description: "This column has a value getter and is not sortable.",
      sortable: true,
      width: 200,
      valueGetter: (params) => `${params.row._sum?.customer_operation_amount}`,
    },
    {
      field: "hierarchyCount",
      headerName: "OPERACIONES",
      type: "number",
      description: "This column has a value getter and is not sortable.",
      sortable: true,
      width: 200,
      valueGetter: (params) =>
        `${params.row._count?.customer_operation_amount}`,
    },
    {
      field: "hierarchyTickets",
      headerName: "BOLETOS",
      type: "number",
      description: "This column has a value getter and is not sortable.",
      sortable: true,
      width: 200,
      valueGetter: (params) => `${params.row._count?.tickets}`,
    },
  ];

  const handleCallbackFilter = async (filterParams) => {
    if (!filterParams.lotteryId) return;
    await setFilter(filterParams);
    await resetTable();
  };

  return (
    <div className="m-2 md:m-10 p-2 md:p-10 bg-white bg-opacity-80 rounded-3xl ">
      <Header category="Página" title="Operaciones por Agencia" />
      <div className="flex flex-row justify-between items-center">
        <div className="flex-1 flex-col">
          <div>
            <FilterData parentCallback={handleCallbackFilter} />
          </div>
          <div>
            {dataFetched && (
              <Box sx={{ width: "100%" }}>
                <DataGrid
                  key={seed}
                  rows={dataFetched}
                  columns={columns}
                  slots={{
                    toolbar: CustomToolbar,
                  }}
                  getRowId={(row) => row?.hierarchy_id_origin}
                  initialState={{
                    columns: {
                      columnVisibilityModel: {
                        hierarchy_id_origin: false,
                      },
                    },
                    pagination: {
                      paginationModel: { page: 0, pageSize: 25 },
                    },
                  }}
                  pageSizeOptions={[25, 50, 100]}
                />
              </Box>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OperationsByHierarchy;
