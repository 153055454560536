import useSWR, { preload, useSWRConfig } from "swr";
import {
  fetcher,
  fetcherUnAuth,
  fetcherPayload,
  getter,
  poster,
  updater,
  remover,
  swrOptions,
} from "./index";

const API_ENDPOINT = "/customer";

// const swrOptions = {
//   revalidateOnReconnect: true,
//   revalidateOnFocus: true,
//   dedupingInterval: 3000, // you can only refetched after x milisecons
//   refreshInterval: 1500, // refetch data every x miliseconds
// };

export const PreGetCustomers = (props) => {
  try {
    return preload(`${API_ENDPOINT}/all`, fetcher);
  } catch (error) {
    throw new Error(error);
  }
};

export const GetCustomers = (props) => {
  try {
    return useSWR(`${API_ENDPOINT}/all`, fetcher, swrOptions);
  } catch (error) {
    throw new Error(error);
  }
};

export const ExistCustomer = async (id) => {
  try {
    return await getter(`${API_ENDPOINT}/identification/${id}`);
  } catch (error) {
    throw new Error(error);
  }
};

export const SaveCustomer = (payload) => {
  try {
    return poster(API_ENDPOINT, payload);
  } catch (error) {
    throw new Error(error);
  }
};

export const SaveManyCustomer = (payload) => {
  try {
    return poster(`${API_ENDPOINT}/many`, payload);
  } catch (error) {
    throw new Error(error);
  }
};

export const UpdateCustomer = (payload) => {
  try {
    return updater(`${API_ENDPOINT}`, payload);
  } catch (error) {
    throw new Error(error);
  }
};

export const RemoveCustomer = (id, payload) => {
  try {
    return remover(`${API_ENDPOINT}/${id}`);
  } catch (error) {
    throw new Error(error);
  }
};

export default {
  PreGetCustomers,
  GetCustomers,
  ExistCustomer,
  SaveCustomer,
  RemoveCustomer,
  UpdateCustomer,
  SaveManyCustomer,
};
