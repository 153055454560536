import React, { useState, useEffect } from "react";
import useSWR, { preload } from "swr";
import { Header } from "../components";

import { fetcher, swrOptions} from "../api/index";

import { useStateContext } from "../contexts/ContextProvider";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea, CardActions } from "@mui/material";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import SendIcon from "@mui/icons-material/Send";

import Modal from "@mui/material/Modal";

import Grid from "@mui/material/Grid"; // Grid version 1

import validator from "ecuador-validator";
import {PreGetLotteries, GetLotteries} from '../api/lottery';
import { SaveOperation } from "../api/operation";
import { ExistCustomer } from "../api/customer";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const remoteUploadsUrl=process.env.REACT_APP_REMOTE_UPLOADS_URL;

// preload("/lottery/all", fetcher, swrOptions);

PreGetLotteries();

const Lottery = (props) => {
  // Data
  const { data, error, isLloading } = GetLotteries();

  // Global Context
  const {
    setOpenGlobalModal,
    setMessageGlobalModal,
    setOpenGlobalModalError,
    setMessageGlobalModalError,
    userLogged,
  } = useStateContext();

  const { onClose, value: valueProp, open, ...other } = props;

  const [list, setList] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const [messageModal, setMessageModal] = useState("");

  const [selectedCard, setSelectedCard] = useState(null);

  const [inputCedula, setInputCedula] = useState("");
  const [errorCedula, setErrorCedula] = useState(true);
  const [phone, setPhone] = useState("");
  const [showPhone, setShowPhone] = useState(false);

  const [inputComprobante, setInputComprobante] = useState("");
  const [errorComprobante, setErrorComprobante] = useState(true);

  const [inputMonto, setInputMonto] = useState(0);
  const [errorMonto, setErrorMonto] = useState(true);

  const MIN_AMOUNT = 5;
  const MAX_AMOUNT = 25000;

  const API_ENDPOINT='/customer';

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const clearFields =()=>{
    // Limpiar campos
    setInputCedula("");
    setInputMonto(0);
    setInputComprobante("");
    setPhone(""); setShowPhone(false);
  }

  const handleClose = () => {
    clearFields();
    setOpenDialog(false);
  };

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handleInputCedula = (event) => {
    const value = event.target.value;
    if (value.length < 10 || value.length > 13) {
      setInputCedula(value.substring(0, 13));
      // setErrorCedula(true)
    } else {
      setErrorCedula(false);
      setInputCedula(value);
    }
  };

  const handleLeaveCedula = async (event) => {
    const _cedula = event.target.value;
    setPhone(""); setShowPhone(false);

    // Identification validation
    if (!validator.ci(_cedula) && !validator.ruc(_cedula)) {
      setMessageModal(
        `Cedula/RUC ${_cedula} es invalida.\r\nRevise por favor.`
      );
      setOpenModal(true);
      return;
    }

    const _customer= await ExistCustomer(_cedula);
    if (_customer){
      setPhone(_customer.customer_phone)
      setShowPhone(true);
    }else {
      setMessageModal(
        `Cedula/RUC ${_cedula} no existe en la plataforma.\r\nRevise por favor.`
      );
      setOpenModal(true);
    }

  }

  const handleInputComprobante = (event) => {
    const value = event.target.value;
    if (value.length < 5 || value.length > 15) {
      setInputComprobante(value.substring(0, 15));
      // setErrorComprobante(true)
    } else {
      setErrorComprobante(false);
      setInputComprobante(value);
    }
  };

  const handleInputMonto = (event) => {
    const value = Number(event.target.value);
    if (value < MIN_AMOUNT || value > MAX_AMOUNT) {
      setInputMonto(value);
      // setErrorMonto(true)
    } else {
      setInputMonto(value);
      setErrorMonto(false);
    }
  };

  /* BACKDROP */
  const handleBackdropClick = (e) => {
    //these fail to keep the modal open
    e.stopPropagation();
    return false;
  };

  // console.log(remoteUploadsUrl);
  return (
    <div className="m-2 md:m-5 p-2 md:p-5 bg-white bg-opacity-80 rounded-3xl ">
      <Header category="Página" title="Loterias" />

      {/* *** DIALOG *** */}
      <Dialog
        sx={{ "& .MuiDialog-paper": { width: "100%", maxHeight: 435 } }}
        maxWidth="xs"
        {...other}
        open={openDialog}
        onClick={(e) => e.stopPropagation()}
        disableEscapeKeyDown={true}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());

            // Amout Validation
            const _amount = Number(formJson.monto);
            const _cedula = formJson.cedula;
            const _comprobante = formJson.comprobante;

            if (_amount > MAX_AMOUNT) {
              setMessageModal(
                `Monto es superior a ${MAX_AMOUNT} corriga por favor`
              );
              setOpenModal(true);
              return;
            }
            if (_amount < MIN_AMOUNT) {
              setMessageModal(
                `Monto es menor a ${MIN_AMOUNT} corrija por favor.`
              );
              setOpenModal(true);
              return;
            }
            // Identification validation
            if (!validator.ci(_cedula) && !validator.ruc(_cedula)) {
              setMessageModal(
                `Cedula/RUC ${_cedula} es invalida.\r\nRevise por favor.`
              );
              setOpenModal(true);
              return;
            }

            // Document Validation
            if (!_comprobante.startsWith(selectedCard.lottery_control_code)) {
              setMessageModal(
                `El comprobante ${_comprobante} no es valido para esta promoción.\r\nRevise por favor.`
              );
              setOpenModal(true);
              return;
            }

            // Verify if the identification exists
            // ** this is doing in database

            // Enviar al ENDPOINT y espeerar respuesta antes de cerrar
            const activeUser=JSON.parse(userLogged);
            const payload = {
              userId: activeUser.user_id,
              hierarchyId: activeUser.hierarchy_id,
              customerIdentification: _cedula,
              lotteryId: selectedCard.lottery_id,
              customerOperationReference: (_comprobante + '-' + _cedula),
              customerOperationAmount: _amount,
            };

            //************************************* */
            SaveOperation(payload)
              .then( (res) => {

                // console.log(`Operacion grabada: ${JSON.stringify(res)}`);

                // Mostrar modal principal
                const totTickets=res.ticket.length;
                const firstTicket=res.ticket[0].ticket_id;
                const lastTicket=res.ticket[totTickets-1].ticket_id;
                setMessageGlobalModal(`La Operación se grabo correctamente y genero ${totTickets} tickets.\r\nDesde el #${firstTicket} hasta el #${lastTicket}`);
                setOpenGlobalModal(true);
                clearFields()
                handleClose();
              })
              .catch( (error) => {
                // console.log(JSON.stringify(error.message));
                setMessageModal(
                  `Error al intentar grabar operación [${error.message}].\r\nRevise por favor.`
                );
                setOpenModal(true);
                return;
              });

          },
        }}
      >
        <DialogTitle>{selectedCard?.lottery_name}</DialogTitle>
        <DialogContent>
          <div>
            {/* *** MODAL *** */}
            <Modal
              keepMounted
              open={openModal}
              onClose={handleCloseModal}
              aria-labelledby="keep-mounted-modal-title"
              aria-describedby="keep-mounted-modal-description"
            >
              <Box sx={style}>
                <Typography
                  id="keep-mounted-modal-title"
                  variant="h6"
                  component="h2"
                >
                  {messageModal}
                </Typography>
                {/* <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>
                  Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
                </Typography> */}
              </Box>
            </Modal>
          </div>
          <DialogContentText>
            {`Costo del Boleto: USD$ ${selectedCard?.lottery_ticket_cost}`}
          </DialogContentText>
          {showPhone && (
            <DialogContentText style={{color:'red', fontWeight:'bolder', fontSize:'22px'}}>
              {`Celular: ${phone}`}
            </DialogContentText>
          )}
          <TextField
            autoFocus
            required
            margin="dense"
            id="cedula"
            name="cedula"
            label="Cedula/RUC Socio"
            type="number"
            fullWidth
            variant="standard"
            value={inputCedula}
            onInput={handleInputCedula}
            onBlurCapture={handleLeaveCedula}
            error={errorCedula}
            helperText="Debe ingresar una cédula valida."
          />
          <FormControl fullWidth sx={{ m: 1 }} variant="standard">
            <InputLabel htmlFor="label-monto">Monto</InputLabel>
            <Input
              // autoFocus
              required
              id="monto"
              name="monto"
              value={inputMonto}
              onInput={handleInputMonto}
              error={errorMonto}
              startAdornment={
                <InputAdornment position="start">$</InputAdornment>
              }
            />
          </FormControl>
          <TextField
            // autoFocus
            required
            margin="dense"
            id="comprobante"
            name="comprobante"
            label="# Comprobante"
            type="number"
            fullWidth
            variant="standard"
            value={inputComprobante}
            onInput={handleInputComprobante}
            error={errorComprobante}
            helperText="Debe ingresar un comprobante valido."
          />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="error" onClick={handleClose}>
            Cancelar
          </Button>
          <Button type="submit" variant="contained" endIcon={<SendIcon />}>
            Generar Boletos
          </Button>
        </DialogActions>
      </Dialog>

      <div className=" items-center justify-between">
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          {data &&
            data.map((item, index) => (
              <Grid item xs={2} sm={4} md={4} key={index}>
                <Card
                  onClick={() => {
                    setSelectedCard(item);
                    handleClickOpen();
                  }}
                >
                  <CardActionArea>
                    <CardMedia>
                      <div >
                        <img src={`${remoteUploadsUrl}/${item.lottery_image_url}`} alt={item.lottery_name} style={{height: "25vh", width: "100%"}}  />
                      </div>
                    </CardMedia>
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div">
                        {item.lottery_name}
                      </Typography>
                      <Typography variant="body1" color="text.secondary" style={{fontWeight:'bold', color:'green'}}>
                        {`Precio por Boleto: USD$ ${item.lottery_ticket_cost}`}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            ))}
        </Grid>
      </div>
    </div>
  );
};

export default Lottery;
